import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAnalytics } from '../../app-theme';
import { PageLabels } from '../../app-theme/constants';

import Store1 from '../../assets/store1.svg';
import Stars from '../../assets/stars_emoji.jpg';
import './coming-soon-template.scss';
import PageLayout from '../page-layout/page-layout';

const ComingSoonTemplate = ({
  isTelcoSuccess,
}) => {
  const { pageLabel } = useAnalytics({
    pageLabel: isTelcoSuccess ? PageLabels.TELCO_COMING_SOON : PageLabels.COMING_SOON,
  });
  const [translate] = useTranslation('comingSoon');

  const {
    title,
    subTitle,
    message,
  } = useMemo(() => {
    if (isTelcoSuccess) {
      return {
        title: translate('telco.title'),
        subTitle: translate('telco.sub-title'),
        message: translate('telco.coming-soon'),
      };
    }
    return {
      title: translate('title'),
      subTitle: translate('sub-title'),
      message: translate('coming-soon'),
    };
  }, [isTelcoSuccess, translate]);

  return (
    <PageLayout
      className="coming-soon-page"
      pageLabel={pageLabel}
    >
      <div className="coming-soon-page">
        <div className="title-div">
          <span className="title">{title}</span>
          <img src={Stars} alt="✨" className="image-stars" />
          { subTitle !== '' ? (<p className="sub-title">{subTitle}</p>) : null}
        </div>
        <div className="message">{message}</div>
        <img
          className="image"
          src={Store1}
          alt="store"
        />
      </div>
    </PageLayout>
  );
};

export default ComingSoonTemplate;
