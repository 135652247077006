import {
  ApolloClient, InMemoryCache, HttpLink, ApolloLink, from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { API_URI, TOKEN_KEY } from './config';
// import { sendNotification } from './utils/sendNotification';

const timeoutLink = new ApolloLinkTimeout(120000); // 120 second timeout

const httpLink = new HttpLink({ uri: API_URI });

// const roundTripLink = new ApolloLink((operation, forward) => {
//   // Called before operation is sent to server
//   operation.setContext({ start: new Date() });

//   return forward(operation).map((data) => {
//     // Called after server responds
//     const time = new Date() - operation.getContext().start;
//     console.log(`Operation ${operation.operationName} took ${time} to complete`);
//     return data;
//   });
// });

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const accessToken = localStorage.getItem(TOKEN_KEY);
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...(!!accessToken && ({
        Authorization: accessToken,
      })),
    },
  }));
  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, code, path }) => {
      console.log(`[${code}]: ${message} at ${path}`);
    });

    if (graphQLErrors && graphQLErrors.some(({ code }) => code === 'UNAUTHENTICATED')) {
      const hasToken = Boolean(localStorage.getItem(TOKEN_KEY));
      if (hasToken) {
        localStorage.clear();
        sessionStorage.clear();
      }
      window.location.reload();
    }
  }

  if (networkError) {
    // sendNotification(networkError.message, '', 'error');
    console.log(`[Network error]: ${networkError}`);
  }
});

const cache = new InMemoryCache();

const defaultOptions = {
  watchQuery: {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  },
  query: {
    fetchPolicy: 'network-only',
  },
};

const client = new ApolloClient({
  link: from([authMiddleware, errorLink, timeoutLink, httpLink]),
  cache,
  defaultOptions,
});

export default client;
