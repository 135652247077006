import React from 'react';
import { Carousel } from 'react-responsive-carousel';

import Carousel1 from '../../assets/SukiClubPromos-1.png';
import Carousel2 from '../../assets/login_carousel_2.png';
import Carousel3 from '../../assets/login_carousel_3.png';
import poweredBy from '../../assets/powered_by_growsari.svg';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './login-carousel.scss';

const LoginCarousel = ({ onScroll, carouselItems = [] }) => {
  const images = [Carousel1, Carousel2, Carousel3];
  return (
    <Carousel
      swipeable
      emulateTouch
      showThumbs={false}
      showArrows={false}
      statusFormatter={() => null}
      preventMovementUntilSwipeScrollTolerance
      onChange={(currentSlideIndex) => onScroll(currentSlideIndex + 1)}
    >
      {carouselItems.map((item, i) => (
        <div key={item.id} className="carousel-item">
          <p className="carousel-title">
            {item.title}
          </p>
          <img
            className="carousel-image"
            id={`carousel-image-${item.id}`}
            src={images[i]}
            alt={item.alt}
            // style={{
            //   width: '216px',
            //   height: item.id === 1 ? '160px' : '216px',
            // }}
          />
          {item.id === 1 && (
            <img
              src={poweredBy}
              alt={item.alt}
              style={{
                width: '125px',
                height: '25px',
              }}
            />
          )}
          <p className="carousel-text">
            {item.text}
          </p>
        </div>
      ))}
    </Carousel>
  );
};
export default LoginCarousel;
