import React from 'react';
import { Modal } from 'antd';

import './voucher-details.scss';

const ThanksSuki = ({
  openThanksModal,
  setOpenThanksModal,
  image,
}) => (
  <Modal
    centered
    visible={openThanksModal}
    maskClosable={false}
    footer={null}
    onCancel={() => setOpenThanksModal(false)}
  >
    <div className="thanks-modal">
      <img
        className="thanks-suki"
        src={image}
        alt="Thanks Suki"
      />
    </div>
  </Modal>
);

export default ThanksSuki;
