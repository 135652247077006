import { gql } from '@apollo/client';

export const generateOtpQuery = gql`
    mutation GenerateOTP($mobile: String!){
        generate_otp(phone_number: $mobile){
            code
        }
    }`;

export const verifyOtpQuery = gql`
mutation VerifyOTP($code: String! ,$mobile: String!){
    verify_otp(otp: $code , phone_number: $mobile ){
    id
    name
    access_token
    id_token
    refresh_token
    }
}`;

export const claimCouponQuery = gql`
  mutation ClaimCoupon($coupon_id: ID, $coupon_code: String){
    claim_coupon(coupon_id: $coupon_id, coupon_code: $coupon_code){
      id
      suki_id
      store_id
      coupon {
        id,
        code,
        suki_title,
        suki_subtitle,
        start_at,
        end_at,
        discount,
        thumbnail_url,
      }
      start_at
      end_at
      redeemed_at
      status
    }
  }
`;

export const registerSuki = gql`
  mutation RegisterSuki(
    $phone_number: String!,
    $first_name: String!,
    $last_name: String!,
    $gender: String!,
    $birthday: String!,
    $reg_campaign: String,
    $reg_campaign_id: String,
  )
  {
    register(
      phone_number: $phone_number,
      first_name: $first_name,
      last_name: $last_name,
      gender: $gender,
      birthday: $birthday,
      reg_campaign: $reg_campaign,
      reg_campaign_id: $reg_campaign_id,
    )
    {
      id
      phone_number
      first_name
      last_name
      gender
      birthday
      registered
      registration_type
      province
      city
      barangay
      email
      marital_status
      no_of_children
      employment_status
      monthly_income
      telco_provider_id
      promos_enabled
      telco_provider_id
      telco_load_eligible
      preferred_categories
      enrolled_stores {
        id
        name
        address
      }
    }
  }
`;

export const updateProfileQuery = gql`
  mutation UpdateProfile(
    $first_name: String,
    $last_name: String,
    $gender: String,
    $birthday: String,
    $province: String,
    $city: String,
    $barangay: String,
    $email: String,
    $marital_status: String,
    $no_of_children: String,
    $employment_status: String,
    $monthly_income: String,
    $preferred_categories: [ID]
    $telco_provider_id: String
  )
  {
    update_profile(
      first_name: $first_name,
      last_name: $last_name,
      barangay: $barangay,
      province: $province,
      city: $city,
      gender: $gender,
      birthday: $birthday,
      email: $email,
      marital_status: $marital_status,
      no_of_children: $no_of_children,
      employment_status: $employment_status,
      monthly_income: $monthly_income,
      preferred_categories: $preferred_categories,
      telco_provider_id: $telco_provider_id
    )
    {
      id
      phone_number
      first_name
      last_name
      gender
      birthday
      registered
      registration_type
      province
      city
      barangay
      email
      marital_status
      no_of_children
      employment_status
      monthly_income
      telco_provider_id
      promos_enabled
      telco_provider_id
      telco_load_eligible
      preferred_categories
      enrolled_stores {
        id
        name
        address
      }
    }
  }
`;
export const answerDripQuestion = gql`
  mutation AnswerDripQuestion($question_id: String ,$answer: String){
    answer_question(question_id: $question_id, answer: $answer) {
      account_id
      ref_user_id
      question_id
      answer
      service
    }
  }
`;
export const dismissDripQuestion = gql`
  mutation DismissDripQuestion($question_id: String){
    dismiss_question(question_id: $question_id) {
      message
    }
  }
`;
