import React, {
  useState, useCallback, useMemo, useContext,
} from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Button, Spin } from 'antd';

import { PageLayout, ContentTemplate, ProviderCard } from '../../components';
import { useAnalytics, UserContext } from '../../app-theme';
import { AnalyticsEventTypes, ButtonTypes, PageLabels } from '../../app-theme/constants';
import { listTelcoProviders } from '../../gql/gql_queries';
import { updateProfileQuery } from '../../gql/gql_mutations';
import { useUserDetails } from '../../custom-hooks';
import { sendNotification } from '../../utils/sendNotification';
import Trophy from '../../assets/trophy.png';

import './telco-providers.scss';

const TelcoProviders = ({
  onProviderUpdated,
}) => {
  const {
    fireEvent,
    fireButtonClickEvent,
    pageLabel,
  } = useAnalytics({ pageLabel: PageLabels.TELCO_NETWORK });
  const [translate] = useTranslation('telcoProviders');
  const { userData, setUserData } = useContext(UserContext);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [updatingProvider, setUpdatingProvider] = useState(false);
  const { displayName } = useUserDetails();

  const {
    data: telcoProviders,
    loading: loadingTelcoProviders,
  } = useQuery(listTelcoProviders);

  const providers = useMemo(
    () => (telcoProviders && telcoProviders.list_telco_providers.collection) || [],
    [telcoProviders],
  );

  const [updateProfile] = useMutation(updateProfileQuery);

  const onSubmit = useCallback((provider) => {
    setUpdatingProvider(true);
    updateProfile({
      variables: {
        ...userData,
        telco_provider_id: provider.id,
      },
    })
      .then(({ data }) => {
        if (data && data.update_profile) {
          setUserData(data.update_profile);
          onProviderUpdated && onProviderUpdated();
        } else {
          sendNotification(translate('errors.update-failed'), '', 'error');
        }
      })
      .catch(() => {
        sendNotification(translate('errors.update-failed'), '', 'error');
      })
      .finally(() => {
        setUpdatingProvider(false);
      });
  }, [updateProfile, userData, setUserData, onProviderUpdated, translate]);

  const onProviderCLick = useCallback((event, provider) => {
    if (selectedProvider && selectedProvider.id === provider.id) {
      setSelectedProvider(null);
      return;
    }
    fireEvent(AnalyticsEventTypes.SELECT_TELCO, {
      ...provider,
    });
    setSelectedProvider(provider);
  }, [selectedProvider, fireEvent]);

  const onContinue = useCallback(() => {
    fireButtonClickEvent({
      button_name: ButtonTypes.CONTINUE_TELCO,
      ...selectedProvider,
    });
    onSubmit(selectedProvider);
  }, [fireButtonClickEvent, onSubmit, selectedProvider]);

  return (
    <PageLayout className="telco-providers">
      <div className="telco-view">
        <img
          className="success-trophy"
          src={Trophy}
          alt="success"
        />
        <div className="congrats-title">
          {translate('congratsTitle', { username: displayName })}
        </div>
        <ContentTemplate pageLabel={pageLabel} contentStyle="unique">
          <div className="subTitle">
            {translate('subTitle')}
          </div>
          {loadingTelcoProviders && (
            <div className="spin-container">
              <Spin className="m-auto" />
            </div>
          )}
          {!loadingTelcoProviders && (
            <>
              {providers.length > 0 && (providers.map((provider) => (
                <ProviderCard
                  key={provider.id}
                  data={provider}
                  imageProps={{
                    src: provider.image_url,
                    alt: 'brand',
                  }}
                  title={provider.name}
                  isSelected={selectedProvider && selectedProvider.id === provider.id}
                  onClick={onProviderCLick}
                />
              )))}
              {providers.length > 0 && (
                <Button
                  className="button continue-button"
                  disabled={selectedProvider === null}
                  loading={updatingProvider}
                  onClick={onContinue}
                >
                  {translate('continue')}
                </Button>
              )}
              {providers.length === 0 && (
                <div className="spin-container">
                  <div className="m-auto">
                    No Telco Providers Available
                  </div>
                </div>
              )}
            </>
          )}
        </ContentTemplate>
      </div>
    </PageLayout>
  );
};

export default TelcoProviders;
