import React from 'react';
import { Helmet } from 'react-helmet';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { ApolloProvider } from '@apollo/react-hooks';
import { Switch, BrowserRouter, Redirect } from 'react-router-dom';
import apolloClient from './apollo-client';

import { CustomRoute } from './components';
import UserProvider from './components/user-provider/user-provider';
import {
  Login,
  Home,
  RegistrationBasic,
  SelfRegistration,
  ComingSoon,
  HowItWorks,
  PromoDetails,
  VoucherDetails,
  MySavedPromos,
  RegisterLocation,
  RegisterDemographicInfo,
  RegisterPreferredCategories,
} from './pages';
import { GOOGLE_ANALYTICS_ID, DEBUG_MODE } from './config';
import translations from './app-theme/translations/fil';

import './app.scss';

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'fil',
  resources: {
    fil: translations,
  },
});

const App = () => (
  <I18nextProvider i18n={i18next}>
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <div id="app" className="app">
          {GOOGLE_ANALYTICS_ID && (
            <Helmet>
              <script
                async
                src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`}
              />
              <script>
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag() {
                      dataLayer.push(arguments);
                    };
                    gtag('js', new Date());
                    gtag('config', "${GOOGLE_ANALYTICS_ID}", { 'send_page_view': false, debug: ${DEBUG_MODE} });
                  `}
              </script>
            </Helmet>
          )}
          <UserProvider>
            <Switch>
              <CustomRoute exact path="/login" component={Login} />
              <CustomRoute exact path="/self-register" component={SelfRegistration} />
              <CustomRoute exact path="/promo/:code" component={PromoDetails} />
              <CustomRoute exact path="/" component={Home} />
              <CustomRoute exact path="/faqs" component={HowItWorks} />
              <CustomRoute exact isPrivate path="/register" component={RegistrationBasic} />
              <CustomRoute exact isPrivate path="/register-location" component={RegisterLocation} />
              <CustomRoute exact isPrivate path="/demographic-info" component={RegisterDemographicInfo} />
              <CustomRoute exact isPrivate path="/preferences" component={RegisterPreferredCategories} />
              <CustomRoute exact isPrivate path="/vouchers" component={MySavedPromos} />
              <CustomRoute exact isPrivate path="/voucher/:id" component={VoucherDetails} />
              <CustomRoute exact isPrivate path="/coming-soon" component={ComingSoon} />
              <Redirect path="*" to="/" />
            </Switch>
          </UserProvider>
        </div>
      </BrowserRouter>
    </ApolloProvider>
  </I18nextProvider>
);

export default App;
