import React, {
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useAnalytics } from '../../app-theme';
import { AnalyticsEventTypes } from '../../app-theme/constants';

import backArrow from '../../assets/back_arrow.png';

import './content-template.scss';

const ContentTemplate = ({
  title,
  contentStyle = 'normal',
  titleType = 'large',
  enableBackButton,
  customBackClick,
  children,
  pageLabel,
}) => {
  const { fireEvent } = useAnalytics({ firePageEventsOnLoad: false, pageLabel });
  const history = useHistory();

  const onBackClick = useCallback(() => {
    fireEvent(AnalyticsEventTypes.GO_BACK);
    if (customBackClick) {
      customBackClick();
    } else if (history.length > 2) {
      history.goBack();
    } else {
      history.push('/');
    }
  }, [customBackClick, fireEvent, history]);

  return (
    <div className={`content-template ${contentStyle}`}>
      <div className="content">
        {title && (
          <div className="header">
            {enableBackButton && (
              <img
                src={backArrow}
                alt="Back"
                className="back"
                onClick={onBackClick}
              />
            )}
            <p className={`title ${titleType}`}>{title}</p>
            {enableBackButton && (
              <div className="placeholder" />
            )}
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default ContentTemplate;
