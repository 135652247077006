import React from 'react';
import { useTranslation } from 'react-i18next';
import questionMark from '../../assets/question_mark.svg';
import step1 from '../../assets/redeem_step_1.png';
import step2 from '../../assets/redeem_step_2.png';
import step3 from '../../assets/redeem_step_3.png';
import './redeem-steps.scss';

const RedeemSteps = () => {
  const [translate] = useTranslation('redeemSteps');
  const steps = translate('steps', { returnObjects: true });
  const images = [step1, step2, step3];
  return (
    <div className="redeem-div">
      <div className="redeem-title">
        <img className="question-mark" src={questionMark} alt="question" />
        {translate('title')}
      </div>
      {steps.map((step, i) => (
        <div key={`step-${step.id}`} className="steps">
          <img className="step-image" src={images[i]} alt={step.id} />
          <div>
            <p className="step-title">{step.title}</p>
            <p className="step-text">{step.text}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RedeemSteps;
