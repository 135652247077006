import React, {
  useCallback, useContext, useState, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Spin } from 'antd';

import {
  OnBoardingStep,
} from '../../components';
import { useAnalytics, UserContext } from '../../app-theme';
import { sendNotification } from '../../utils/sendNotification';
import { AnalyticsEventTypes, ButtonTypes, PageLabels } from '../../app-theme/constants';

import './register-preferences.scss';
import { requireConditions } from '../../hocs';
import { updateProfileQuery } from '../../gql/gql_mutations';
import { listCategory } from '../../gql/gql_queries';
import CategoryCard from '../../components/category-card/category-card';

const MAX_PREFERENCES = 10;

const RegisterPreferredCategories = ({
  referrerLocation,
  completedPreferences,
}) => {
  const {
    fireEvent,
    fireButtonClickEvent,
    pageLabel,
  } = useAnalytics({ pageLabel: PageLabels.REGISTER_PREFERRED_CATEGORIES });
  const [translate] = useTranslation('registerPreferences');
  const [updatingProfile, setUpdatingProfile] = useState(false);
  const {
    setUserData,
  } = useContext(UserContext);
  const history = useHistory();

  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);

  const enableSelection = useMemo(
    () => selectedCategoryIds.length < MAX_PREFERENCES,
    [selectedCategoryIds.length],
  );

  const {
    data: categoriesData,
    loading,
  } = useQuery(listCategory, {
    variables: {
      first: 0,
      after: '',
    },
  });

  const categoriesList = useMemo(() => {
    if (categoriesData && categoriesData.list_category) {
      return categoriesData.list_category.collection;
    }
    return [];
  }, [categoriesData]);

  const [updateProfile] = useMutation(updateProfileQuery);

  // eslint-disable-next-line no-unused-vars
  const onFormSubmit = useCallback(() => {
    setUpdatingProfile(true);
    fireButtonClickEvent({
      button_name: ButtonTypes.SUBMIT,
    });

    updateProfile({
      variables: {
        preferred_categories: selectedCategoryIds,
      },
    })
      .then(({ data }) => {
        if (data && data.update_profile) {
          setUserData(data.update_profile);
          history.replace(referrerLocation);
        } else {
          sendNotification(translate('errors.update-failed'), '', 'error');
        }
      })
      .catch(() => {
        sendNotification(translate('errors.update-failed'), '', 'error');
      })
      .finally(() => {
        setUpdatingProfile(false);
      });
  }, [
    fireButtonClickEvent,
    history,
    referrerLocation,
    selectedCategoryIds,
    setUserData,
    translate,
    updateProfile,
  ]);

  const onSelect = useCallback((category) => {
    fireEvent(AnalyticsEventTypes.SELECT_PREFERRED_CATEGORY, {
      category_id: category.id,
      category_name: category.name,
    });
    setSelectedCategoryIds([
      ...selectedCategoryIds,
      category.id,
    ]);
  }, [fireEvent, selectedCategoryIds]);

  const onDeselect = useCallback((category) => {
    fireEvent(AnalyticsEventTypes.DESELECT_PREFERRED_CATEGORY, {
      category_id: category.id,
      category_name: category.name,
    });
    const updatedList = selectedCategoryIds.filter((selectedId) => selectedId !== category.id);
    setSelectedCategoryIds(updatedList);
  }, [fireEvent, selectedCategoryIds]);

  return (
    <>
      {completedPreferences && (
        <Redirect to={referrerLocation} />
      )}
      <OnBoardingStep
        className="register-preferences no-spacing"
        currentStep={4}
        totalSteps={4}
        pageLabel={pageLabel}
        title={translate('title')}
        subTitle={translate('sub-title')}
        buttonProps={{
          isValid: selectedCategoryIds.length > 0,
          isLoading: updatingProfile,
          name: translate('submit'),
          onClick: onFormSubmit,
        }}
      >
        {loading && (
          <div className="spin-container">
            <Spin className="m-auto" />
          </div>
        )}
        {!loading && categoriesList.length > 0 && (
          <div className="category-list">
            {categoriesList.map((category) => (
              <CategoryCard
                key={`category-${category.id}`}
                enableSelection={enableSelection}
                selectedCategoryIds={selectedCategoryIds}
                category={category}
                onSelect={onSelect}
                onDeselect={onDeselect}
              />
            ))}
          </div>
        )}
      </OnBoardingStep>
    </>
  );
};

export default requireConditions(RegisterPreferredCategories);
