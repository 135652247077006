/* eslint-disable max-len */
import React from 'react';

import './registration-basic.scss';

const Terms = () => (
  <div className="terms-and-conditions">
    <p className="terms-paragraph">
      We are Growsari, Inc. and Growsari Enterprise, Inc. For more information on Growsari, you may visit
      <a
        className="terms-link"
        href="https://business.growsari.com/about-growsari/?_ga=2.46228519.1629774820.1632473330-1874656368.1632473330"
      >
        Growsari
      </a>
    </p>
    <p className="terms-paragraph">
      Growsari respects and values your rights as Data Subject under the Data Privacy Act (DPA). Growsari is committed to protecting personal and sensitive information that it collects, uses and/or processes in accordance with the requirements under the DPA and its IRR. Thus, Growsari implements reasonable and appropriate security measures to maintain the confidentiality, integrity and availability of such information.
    </p>
    <h3 className="terms-heading">Data Privacy Consent Form</h3>
    <p className="terms-paragraph">
      This is to grant consent on the general use and sharing of information obtained from me in the course of any transaction, between me and Growsari, its parent and affiliates and their respective representatives and agents and/or any of Growsari’s accredited third parties (collectively as “Growsari”). I acknowledge and agree that any member of Growsari may share these data between them and such data may constitute my personal or sensitive personal information (which includes without limitation, my name, address, contact details (mobile number and email), as well as information relating to my transactions and communications with any member of Growsari.
    </p>
    <p className="terms-paragraph">
      Said data may be collected, processed, stored, updated, or disclosed by any member of the Growsari by physical, electronic or automated means and shared between and among them or to third parties for (i) regular business use such as marketing initiatives for sari-sari stores and Growsari, data collection and analytics, (ii) for legitimate purposes including but not limited to risk management, know your customer (KYC) checks, prevention and detection of fraud or crime, cross-selling, profiling, (iii) to provide services to me or implement transactions which I request, allow, or authorize, and, (iii) to comply with Growsari’s internal policies and its reporting obligations to comply with (a) Applicable Law, and internal policies or procedures, or (b) any demand and/or requests from any Government Authority for purposes of reporting, regulatory trade reporting, disclosure or other obligations under Applicable Law.
    </p>
    <p className="terms-paragraph">
      My consent authorizes members of Growsari to process, collect, use, store, or disclose my information between them and to other members, to Governmental Authorities, and to any third parties any third party, who acquires the rights and obligations of Growsari; who processes information, transactions, services or accounts on behalf of Growsari; or who requires the information for market research, product and business analysis, or for marketing or advertising activities by Growsari, as may be necessary.
    </p>
    <p className="terms-paragraph">
      My information may continue to be collected, stored, processed and/or shared between and among members of Growsari for five (5) years from the conclusion of my transactions with them and any of their members or until the expiration of the retention limits set by applicable law, whichever comes later. Growsari shall immediately destroy or dispose in a secure manner any information the retention of which is no longer allowed under the said law.
    </p>
    <p className="terms-paragraph">
      I understand that I have the right to access, update, or correct certain personal information, or withdraw consent to the use of any of my information as set out in this letter at any given time by communicating with the Growsari Data Protection Officer at Level 10-1 Fort Legend Tower, 3rd Avenue corner 31st Street, Bonifacio Global City, Taguig City, 1632 or via email
      <a
        className="terms-link"
        href="mailto:secretariat@growsari.com"
        target="_blank"
        rel="noreferrer"
      >
        secretariat@growsari.com
      </a>
      . I recognize that I may file complaints with and/or seek assistance from the National Privacy Commission.
    </p>
  </div>
);

export default Terms;
