import React, { useContext, useMemo } from 'react';
import { DateTime } from 'luxon';
import { default as QRCodeComponent } from 'qrcode.react';
import { UserContext } from '../../app-theme';
import poweredBy from '../../assets/powered-by-GS.svg';

import './qr-code.scss';

const QRCode = ({
  showVoucherDetails = false,
  id = 'user-qr',
  renderAs = 'canvas',
  level = 'H',
  size = 227,
  bgColor = '#FFFFFF',
  fgColor = '#000000',
  includeMargin = true,
  voucher = {},
}) => {
  const {
    userData,
  } = useContext(UserContext);

  const value = useMemo(() => {
    const data = {
      type: 'suki_promos',
      suki: {
        id: userData.id,
      },
      voucher: {
        id: voucher.id,
      },
    };
    return btoa(JSON.stringify(data));
  }, [userData.id, voucher.id]);

  const subTitle = useMemo(() => {
    const date = DateTime.fromISO(voucher.end_at).setZone('Asia/Manila');
    return `Valid till ${date.toLocaleString(DateTime.DATE_MED)}`;
  }, [voucher]);

  return (
    <div className="qr-code">
      <QRCodeComponent
        id={id}
        value={value}
        renderAs={renderAs}
        size={size}
        bgColor={bgColor}
        fgColor={fgColor}
        level={level}
        includeMargin={includeMargin}
      />
      {showVoucherDetails && !!voucher && (
        <div className="qr-code-promo-title">
          {voucher && voucher.coupon.suki_title}
          <div className="qr-code-promo-valid">
            {subTitle}
          </div>
        </div>
      )}
      <img
        src={poweredBy}
        alt="powered by"
        style={{
          width: '117px',
          height: '16px',
        }}
      />
    </div>
  );
};

export default QRCode;
