/* eslint-disable no-var */
var response = {};

const getProvinceList = (localityList) => {
  var provinceOptions = [];
  response = localityList;
  if (response && response.city_list) {
    response.city_list.forEach((city) => {
      if (!provinceOptions.some((province) => province.label === city.province)) {
        provinceOptions.push({ label: city.province, value: city.province });
      }
    });
  }
  return provinceOptions;
};
const getCityList = (province) => {
  var cityOptions = [];
  if (response && response.city_list) {
    response.city_list.forEach((city) => {
      if (city.province === province
            && !cityOptions.some((cityOption) => cityOption.label === city.city)) {
        cityOptions.push({ label: city.city, value: city.city });
      }
    });
  }
  return cityOptions;
};
const getBarangayList = (city) => {
  var barangayOptions = [];
  if (response && response.barangay_list) {
    response.barangay_list.forEach((locality) => {
      if (locality.city === city
          && !barangayOptions.some((barangay) => barangay.value === locality.id)) {
        barangayOptions.push({ label: locality.locality, value: locality.id });
      }
    });
  }
  return barangayOptions;
};
export { getProvinceList, getCityList, getBarangayList };
