import { Button, Modal } from 'antd';
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import {
  storageTypes, useAnalytics, UserContext, useStorage,
} from '../../app-theme';
import { AnalyticsEventTypes, ButtonTypes, PageLabels } from '../../app-theme/constants';

import Carousel1 from '../../assets/SukiClubPromos-1.png';
import Carousel2 from '../../assets/login_carousel_2.png';
import Carousel3 from '../../assets/login_carousel_3.png';
import poweredBy from '../../assets/powered-by-GS.svg';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './homepage_tutorial.scss';
import { IS_HOMEPAGE_TUTORIAL_SHOWN } from '../../config';

const CarouselModal = () => {
  const [translate] = useTranslation('login');
  const { isLoggedIn } = useContext(UserContext);
  const { fireEvent } = useAnalytics({ pageLabel: PageLabels.HOME_PAGE });
  const images = [Carousel1, Carousel2, Carousel3];
  const carouselItems = translate('login_carousel', { returnObjects: true });
  const [currentSlideNum, setCurrentSlideNum] = useState(0);
  const [open, setOpen] = useState(false);
  const [isModalShown, setIsModalShown] = useStorage(IS_HOMEPAGE_TUTORIAL_SHOWN, false, storageTypes.SESSION);
  const onScroll = useCallback((currentSlide) => {
    fireEvent(AnalyticsEventTypes.SCROLL_CAROUSEL, {
      screen_name: PageLabels.HOME_PAGE,
      name_of_carousel: PageLabels.HOMEPAGE_TUTORIAL_CAROUSEL,
      scroll_depth_of_carousel: currentSlide,
    });
  }, [fireEvent]);

  const onNextClick = () => {
    fireEvent(AnalyticsEventTypes.CLICK_POPUP_BUTTON, {
      popup_name: PageLabels.HOMEPAGE_TUTORIAL,
      button_name: ButtonTypes.NEXT,
    });
    setCurrentSlideNum(currentSlideNum + 1);
  };

  const onGetStartedClick = () => {
    fireEvent(AnalyticsEventTypes.CLICK_POPUP_BUTTON, {
      popup_name: PageLabels.HOMEPAGE_TUTORIAL,
      button_name: ButtonTypes.GET_STARTED,
    });
    setOpen(false);
  };

  const handleModalClose = () => {
    fireEvent(AnalyticsEventTypes.CLOSE_POPUP, {
      popup_name: PageLabels.HOMEPAGE_TUTORIAL,
    });
    setOpen(false);
  };

  useEffect(() => {
    if (!isLoggedIn && !isModalShown) {
      setOpen(true);
      fireEvent(AnalyticsEventTypes.VIEW_POPUP, {
        popup_name: PageLabels.HOMEPAGE_TUTORIAL,
      });
      setIsModalShown(true);
    }
  }, [fireEvent, isLoggedIn, isModalShown, setIsModalShown]);

  return (
    <Modal
      visible={open}
      maskClosable={false}
      footer={null}
      className="homepage_tutorial_modal"
      onCancel={handleModalClose}
    >
      <Carousel
        swipeable
        emulateTouch
        showThumbs={false}
        showArrows={false}
        statusFormatter={() => null}
        preventMovementUntilSwipeScrollTolerance
        selectedItem={currentSlideNum}
        onChange={(currentSlideIndex) => {
          setCurrentSlideNum(currentSlideIndex);
          onScroll(currentSlideIndex + 1);
        }}
      >
        {carouselItems.map((item, i) => (
          <div key={item.id} className="carousel-item">
            <p className="carousel-title">
              {item.title}
              {item.id === 1 && <p>Suki Club Promos</p>}
            </p>
            <img
              className="carousel-image"
              id={`carousel-image-${item.id}`}
              src={images[i]}
              alt={item.alt}
            />
            {item.id === 1 && (
              <img
                src={poweredBy}
                alt={item.alt}
                style={{
                  width: '125px',
                  height: '25px',
                }}
              />
            )}
            <p className="carousel-text">
              {item.text}
            </p>
          </div>
        ))}
      </Carousel>
      <div>
        {currentSlideNum !== 2
          ? <Button className="button carousel-button" onClick={onNextClick}>Next</Button>
          : <Button className="button carousel-button" onClick={onGetStartedClick}>Get Started</Button>}
      </div>
    </Modal>
  );
};

export default CarouselModal;
