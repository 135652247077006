import React from 'react';
import { useAnalytics } from '../../app-theme';
import { PageLabels } from '../../app-theme/constants';
import { PageLayout, RedeemSteps, SukiPromoClubImageBackGround } from '../../components';

const HowItWorks = () => {
  const { pageLabel } = useAnalytics({ pageLabel: PageLabels.HOW_IT_WORKS });
  return (
    <PageLayout pageLabel={pageLabel} className="no-spacing" isBackButtonEnabled>
      <SukiPromoClubImageBackGround showStore />
      <RedeemSteps />
    </PageLayout>
  );
};
export default HowItWorks;
