import { useCallback, useContext, useEffect } from 'react';
import { parse } from 'query-string';
import { AnalyticsEventTypes } from '../constants';
import { UserContext } from '../contexts';

const getDefaultParameters = (userData, pageLabel) => ({
  timestamp: Date.now().toString(),
  screen_name: pageLabel,
  ...(userData && {
    suki_id: userData.id,
    suki_type: userData.registration_type,
  }),
});

const useAnalytics = ({
  firePageEventsOnLoad = true,
  pageLabel,
}) => {
  const {
    userData,
  } = useContext(UserContext);

  /**
   * we support arguments for google event fire
   * Can be found at https://github.com/react-ga/react-ga
   */
  const fireEvent = useCallback(async (eventType, eventParams = {}, fireCount = 0) => {
    const defaultParams = getDefaultParameters(userData, pageLabel);
    const params = {
      ...defaultParams,
      ...eventParams,
    };
    if (window && window.gtag) {
      window.gtag('event', eventType, params);
    } else if (fireCount < 5) {
      setTimeout(fireEvent, 250, eventType, eventParams, fireCount + 1);
    } else {
      // Failed to send analytics event
    }
  }, [pageLabel, userData]);

  const firePageViewEvent = useCallback(() => {
    fireEvent('page_view', {
      page_title: pageLabel,
      page_path: window.location.pathname + window.location.search,
    });
  }, [fireEvent, pageLabel]);

  const fireViewScreenEvent = useCallback((screenName, previousPage) => {
    const searchParams = parse(window.location.search);
    fireEvent(AnalyticsEventTypes.VIEW_SCREEN, {
      screen_name: screenName,
      previous_screen_name: previousPage,
      registered: userData.registered || false,
      ...searchParams,
    });
    sessionStorage.setItem('PreviousPage', screenName);
  }, [fireEvent, userData]);

  const fireButtonClickEvent = useCallback((parameters) => {
    fireEvent(AnalyticsEventTypes.CLICK_BUTTON, parameters);
  }, [fireEvent]);

  const fireCheckBoxClickEvent = useCallback((parameters) => {
    fireEvent(AnalyticsEventTypes.CLICK_CHECKBOX, parameters);
  }, [fireEvent]);

  useEffect(() => {
    const previousPage = sessionStorage.getItem('PreviousPage') || '';
    if (firePageEventsOnLoad) {
      firePageViewEvent();
      fireViewScreenEvent(pageLabel, previousPage);
    }
  }, [fireViewScreenEvent, firePageEventsOnLoad, pageLabel, firePageViewEvent]);

  return {
    fireEvent,
    fireViewScreenEvent,
    firePageViewEvent,
    fireButtonClickEvent,
    fireCheckBoxClickEvent,
    pageLabel,
  };
};

export default useAnalytics;
