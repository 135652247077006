import React, { useContext, useMemo } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from '../../app-theme';

const CustomRoute = ({
  component: RouteComponent,
  isPrivate = false,
  location,
  ...rest
}) => {
  const { isLoggedIn } = useContext(UserContext);
  const referrerLocation = useMemo(() => {
    const { referrer, ...others } = location.state || {};
    const loc = {
      pathname: (referrer && referrer.pathname) || '/',
      search: (referrer && referrer.search) || '',
      state: (referrer && referrer.state) || {},
    };
    return {
      ...loc,
      state: {
        ...loc.state,
        ...others,
      },
    };
  }, [location.state]);

  const referrerState = useMemo(() => {
    const { referrer, ...others } = location.state || {};
    return {
      referrer: referrer || location,
      ...others,
    };
  }, [location]);

  return (
    <Route
      {...rest}
      render={(props) => ((isLoggedIn || !isPrivate) ? (
        <RouteComponent
          referrerLocation={referrerLocation}
          referrerState={referrerState}
          {...props}
        />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              ...referrerState,
            },
          }}
        />
      ))}
    />
  );
};

export default CustomRoute;
