import React, { useCallback, useMemo } from 'react';
import CheckMark from '../../assets/check_mark.png';

import './category-card.scss';

const CategoryCard = ({
  selectedCategoryIds = [],
  enableSelection = true,
  category,
  onSelect,
  onDeselect,
}) => {
  const {
    id,
    icon,
    name,
  } = category;

  const isSelected = useMemo(
    () => selectedCategoryIds.find((catId) => (catId === id)),
    [id, selectedCategoryIds],
  );

  const disable = useMemo(
    () => !isSelected && !enableSelection,
    [enableSelection, isSelected],
  );

  const onClickCard = useCallback(() => {
    if (isSelected) {
      onDeselect && onDeselect(category);
    } else if (enableSelection) {
      onSelect && onSelect(category);
    }
  }, [category, enableSelection, isSelected, onDeselect, onSelect]);

  return (
    <div
      className={`category-card ${isSelected && 'selected'}`}
      onClick={onClickCard}
    >
      {isSelected && (
        <img className="selected-check" src={CheckMark} alt="check" />
      )}
      {disable && (
        <div className="disable-overlay" />
      )}
      <img
        src="/transparent.png"
        style={{
          backgroundImage: `url(${icon})`,
        }}
        alt="category"
        className="category-icon"
      />
      <div className={`category-name ${isSelected && 'selected'}`}>
        {name}
      </div>
    </div>
  );
};

export default CategoryCard;
