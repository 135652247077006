import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import QRCode from '../qr-code/qr-code';
import Voucher from '../../assets/my_voucher_outline.svg';
import './qr-section.scss';

const QRSection = ({
  id = 'user-qr',
  title,
  message,
  showViewSavedPromos = true,
  onClickAllPromos,
  onBackToHome,
  removeBottomPadding,
  showVoucherDetails = true,
  voucher,
}) => {
  const [translate] = useTranslation('common');
  return (
    <div className={`qr-section ${removeBottomPadding ? 'pb-none' : ''}`}>
      <div className="qr-section-title">
        {title}
      </div>
      <div className="qr-section-description">
        {message}
      </div>
      <QRCode
        showVoucherDetails={showVoucherDetails}
        id={id}
        voucher={voucher}
      />
      {showViewSavedPromos && (
        <>
          <Button
            className="view-promo button"
            onClick={onClickAllPromos}
          >
            <img
              src={Voucher}
              alt="voucher"
              style={{ marginRight: '10px' }}
            />
            {translate('viewSavedPromos')}
          </Button>
          <Button
            className="button outlined"
            onClick={onBackToHome}
          >
            {translate('backToHome')}
          </Button>
        </>
      )}
    </div>
  );
};

export default QRSection;
