import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';
import { useMutation, useLazyQuery } from '@apollo/client';
import { Spin } from 'antd';

import { useAnalytics, UserContext } from '../../app-theme';
import { AnalyticsEventTypes, ButtonTypes, PageLabels } from '../../app-theme/constants';
import {
  PageLayout, QRSection, RedeemSteps, StoresList,
} from '../../components';
import DripQuestion from './drip-question-modal';
import ThanksSuki from './thanks-modal';
import { getDripQuestion } from '../../gql/gql_queries';
import { answerDripQuestion, dismissDripQuestion } from '../../gql/gql_mutations';
import { sendNotification } from '../../utils/sendNotification';

import checkMark from '../../assets/check_mark.svg';
import thanksSuki from '../../assets/thanks_suki.svg';

import './voucher-details.scss';
import { requireConditions } from '../../hocs';

const VoucherDetails = ({ location, match }) => {
  const { pageLabel, fireButtonClickEvent, fireEvent } = useAnalytics({ pageLabel: PageLabels.PROMO_QR });
  const [translate] = useTranslation('voucherDetails');

  const voucherId = useMemo(() => match && match.params && match.params.id, [match]);
  // const {
  //   data: voucherDetails,
  //   loading,
  // } = useQuery(getVoucher, {
  //   variables: {
  //     id: voucherId,
  //   },
  // });
  // const voucher = useMemo(() => (voucherDetails && voucherDetails.get_coupon) || {}, [voucherDetails]);

  const voucher = useMemo(() => location.state && location.state.voucher, [location.state]);
  const showQuestions = useMemo(() => (location.state && location.state.from === '/promo') || false, [location.state]);
  const [openModal, setOpenModal] = useState(false);
  const [openThanksModal, setOpenThanksModal] = useState(false);
  const [fetchDripQuestion, { data: questionInfo, loading: questionLoading }] = useLazyQuery(getDripQuestion);
  const question = useMemo(() => (questionInfo && questionInfo.get_question) || {}, [questionInfo]);

  const {
    enrolledStores,
  } = useContext(UserContext);
  const history = useHistory();

  const [answerQuestion] = useMutation(answerDripQuestion);
  const [dismissQuestion] = useMutation(dismissDripQuestion);

  useEffect(() => {
    if (showQuestions) {
      if (question.id) {
        fireEvent(AnalyticsEventTypes.VIEW_DRIP_POPUP, {
          promo_id: voucherId,
          drip_question_id: question.id,
          view_count: question.prompt_order,
        });
      }
      fetchDripQuestion();
      setOpenModal(true);
    }
  }, [fetchDripQuestion, fireEvent, question.id, question.prompt_order, showQuestions, voucherId]);

  const handleOptionSubmit = useCallback((value) => {
    fireEvent(AnalyticsEventTypes.ANSWER_DRIP_POPUP, {
      promo_id: voucherId,
      drip_question_id: question.id,
      view_count: question.prompt_order,
      answer: value,
    });
    answerQuestion({
      variables: {
        question_id: question.id,
        answer: value,
      },
    }).then(() => {
      setOpenModal(false);
      setOpenThanksModal(true);
      const timer = setTimeout(() => setOpenThanksModal(false), 1000);
      return () => clearTimeout(timer);
    }).catch(() => {
      setOpenModal(false);
      sendNotification(translate('errors.answerFailure'), '', 'error');
    });
  }, [fireEvent, voucherId, question, answerQuestion, translate]);

  const handleDripModalClose = useCallback(() => {
    fireEvent(AnalyticsEventTypes.CLOSE_DRIP_POPUP, {
      promo_id: voucherId,
      drip_question_id: question.id,
      view_count: question.prompt_order,
    });
    setOpenModal(false);
    dismissQuestion({
      variables: {
        question_id: question.id,
      },
    }).then(() => {
      // Do nothing
    }).catch(() => {
      // Do nothing
    });
  }, [dismissQuestion, fireEvent, question, voucherId]);

  const gotoSavedPromos = useCallback(() => {
    fireButtonClickEvent({
      button_name: ButtonTypes.VIEW_SAVED_PROMOS,
    });
    history.push('/vouchers');
  }, [fireButtonClickEvent, history]);

  const goBackToHome = useCallback(() => {
    fireButtonClickEvent({
      button_name: ButtonTypes.GO_HOME,
    });
    history.push('/');
  }, [fireButtonClickEvent, history]);

  if (!(voucherId)) {
    return (
      <Redirect to="/" />
    );
  }

  return (
    <PageLayout
      pageLabel={pageLabel}
      className="promo-claim"
    >
      {showQuestions && questionLoading && <Spin style={{ padding: '10px' }} />}
      {!questionLoading && (
        <>
          <QRSection
            id="qr"
            title={<img src={checkMark} alt="check" />}
            message={translate('qrMessage')}
            voucher={voucher}
            onClickAllPromos={gotoSavedPromos}
            onBackToHome={goBackToHome}
          />
          <RedeemSteps />
          <StoresList
            pageLabel={pageLabel}
            stores={enrolledStores}
          />
          {question && question.id && (
          <>
            <DripQuestion
              openModal={openModal}
              handleDripModalClose={handleDripModalClose}
              question={question}
              handleOptionSubmit={handleOptionSubmit}
            />
            <ThanksSuki
              openThanksModal={openThanksModal}
              setOpenThanksModal={setOpenThanksModal}
              image={thanksSuki}
            />
          </>
          )}
        </>
      )}
    </PageLayout>
  );
};

export default requireConditions(VoucherDetails);
