import React, { useCallback, useMemo, useState } from 'react';
import { storageTypes, useStorage, UserContext } from '../../app-theme';
import { INFO_KEY, TOKEN_KEY, PHONE_KEY } from '../../config';
import UserDataFetch from './user-data-fetch';

const UserProvider = ({
  children,
}) => {
  const [userData, setUserData] = useState({});

  const [, setLoginInfo, removeLoginInfo] = useStorage(INFO_KEY, null);
  const [accessToken, setAccessToken, removeAccessToken] = useStorage(TOKEN_KEY, null);
  const [
    userPhoneNumber, setUserPhoneNumber, removeUserPhoneNumber,
  ] = useStorage(PHONE_KEY, null, storageTypes.SESSION);

  const updateUserLoginData = useCallback((data) => {
    setLoginInfo(JSON.stringify(data));
    setAccessToken(data.access_token);
  }, [setAccessToken, setLoginInfo]);

  const logout = useCallback(() => {
    removeLoginInfo();
    removeAccessToken();
    removeUserPhoneNumber();
  }, [removeAccessToken, removeLoginInfo, removeUserPhoneNumber]);

  const isLoggedIn = useMemo(() => accessToken && accessToken.length > 0, [accessToken]);

  const enrolledStores = useMemo(() => (userData && userData.enrolled_stores) || [], [userData]);

  return (
    <UserContext.Provider
      value={{
        userData,
        setUserData,
        isLoggedIn,
        enrolledStores,
        updateUserLoginData,
        logout,
        userMobileNumber: userPhoneNumber,
        setUserMobileNumber: setUserPhoneNumber,
      }}
    >
      {isLoggedIn && (
        <UserDataFetch>
          {children}
        </UserDataFetch>
      )}
      {!isLoggedIn && (
        <>
          {children}
        </>
      )}
    </UserContext.Provider>
  );
};

export default UserProvider;
