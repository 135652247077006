import React, {
  useState, useCallback, useContext, useMemo,
} from 'react';
import { Redirect } from 'react-router-dom';
import { UserContext } from '../../app-theme';
import { ComingSoonTemplate, SukiPromoClubImageBackGround } from '../../components';
import { requireConditions } from '../../hocs';
import TelcoProviders from '../telco-providers/telco-providers';

const ComingSoon = ({
  location,
}) => {
  const { userData } = useContext(UserContext);
  const isEligibleForTelcoLoad = useMemo(() => userData.telco_load_eligible || false, [userData]);
  const isTelcoProviderAvailable = useMemo(() => userData.telco_provider_id || false, [userData]);

  const [telcoProvided, setTelcoProvided] = useState(false);

  const redirectLocation = useMemo(() => {
    const { state } = location;
    return (state && state.referrer) || '/';
  }, [location]);

  const onProviderUpdated = useCallback(() => {
    setTelcoProvided(true);
  }, []);

  return (
    <>
      {userData && userData.promos_enabled && (
        <Redirect to={redirectLocation} />
      )}
      <SukiPromoClubImageBackGround />
      {(isEligibleForTelcoLoad && !isTelcoProviderAvailable) ? (
        <TelcoProviders
          onProviderUpdated={onProviderUpdated}
        />
      ) : (
        <ComingSoonTemplate
          isTelcoSuccess={telcoProvided}
        />
      )}
    </>
  );
};
export default requireConditions(ComingSoon);
