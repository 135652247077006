import React, { useEffect, useRef, useState } from 'react';
import './voucher_icon_div.scss';

const VoucherIcon = ({ discount }) => {
  const [ellipseTop, setEllipseTop] = useState(8);
  const ref = useRef(null);
  useEffect(() => {
    function updateSize() {
      if (ref.current && ref.current.clientHeight) {
        const top = (ref.current.clientHeight / 2) - 4;
        setEllipseTop(top);
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => { window.removeEventListener('resize', updateSize); };
  }, []);
  return (
    <div className="promo-discount">
      <span className="left-ellipse" style={{ top: ellipseTop }} />
      <div className="discount-text" ref={ref}>{`₱${discount} off`}</div>
      <span className="right-ellipse" style={{ top: ellipseTop }} />
    </div>
  );
};

export default VoucherIcon;
