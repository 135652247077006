import React, {
  useCallback, useEffect, useState, useMemo,
} from 'react';
import { Tabs, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { useQuery } from '@apollo/client';

import { PageLayout, VouchersTabContent } from '../../components';
import { AnalyticsEventTypes, ButtonTypes, PageLabels } from '../../app-theme/constants';
import { useAnalytics } from '../../app-theme';
import { listSukiCoupons } from '../../gql/gql_queries';
import NoActivePromos from '../../assets/no_active_promos.svg';
import NoInActivePromos from '../../assets/no_inactive_promos.svg';
import SavedPromos from '../../assets/saved_promos.png';
import './my-saved-promos.scss';
import { SUKI_COUPON_STATUS } from '../../constants';
import { getSukiCouponEndDate } from '../../utils';
import { requireConditions } from '../../hocs';

const { TabPane } = Tabs;

const MySavedPromos = () => {
  const {
    pageLabel,
    fireButtonClickEvent,
    fireEvent,
  } = useAnalytics({ pageLabel: PageLabels.MY_SAVED_PROMOS });
  const history = useHistory();
  const [translate] = useTranslation('vouchers');

  const [activeVouchers, setActiveVouchers] = useState([]);
  const [inactiveVouchers, setInActiveVouchers] = useState([]);

  const {
    data: vouchersData,
    loading,
  } = useQuery(listSukiCoupons, {
    variables: {
      first: 0,
      after: '',
      status: [
        SUKI_COUPON_STATUS.ACTIVE,
        SUKI_COUPON_STATUS.INACTIVE,
        SUKI_COUPON_STATUS.REDEEMED,
        SUKI_COUPON_STATUS.REDEMPTION_PENDING,
      ],
    },
  });

  const vouchers = useMemo(
    () => (vouchersData && vouchersData.list_suki_coupons.collection) || [],
    [vouchersData],
  );

  const remainingDays = useCallback((voucher) => {
    const date = voucher.status !== SUKI_COUPON_STATUS.REDEEMED
      ? DateTime.fromISO(getSukiCouponEndDate(voucher)).setZone('Asia/Manila')
      : DateTime.fromISO(voucher.redeemed_at).setZone('Asia/Manila');
    const currentTime = DateTime.now().setZone('Asia/Manila');
    const daysLeft = (date - currentTime) / (1000 * 60 * 60 * 24);
    return daysLeft;
  }, []);

  useEffect(() => {
    const active = vouchers.filter((voucher) => voucher.status === SUKI_COUPON_STATUS.ACTIVE);
    const inActive = vouchers.filter((voucher) => voucher.status !== SUKI_COUPON_STATUS.ACTIVE);
    active.sort((v1, v2) => (remainingDays(v1) > remainingDays(v2) ? 1 : -1));
    inActive.sort((v1, v2) => (remainingDays(v1) < remainingDays(v2) ? 1 : -1));
    setActiveVouchers(active);
    setInActiveVouchers(inActive);
  }, [remainingDays, vouchers]);

  const goToAllPromos = useCallback(() => {
    fireButtonClickEvent({
      button_name: ButtonTypes.GO_TO_ALL_PROMOS,
    });
    history.push('/');
  }, [history, fireButtonClickEvent]);

  const getVoucherDetails = useCallback((voucher) => {
    fireEvent(AnalyticsEventTypes.CLICK_VOUCHER, {
      voucher_id: voucher.id,
    });
    history.push({
      pathname: `/voucher/${voucher.id}`,
      state: {
        voucher,
      },
    });
  }, [fireEvent, history]);

  const onTabSwitch = (key) => {
    fireButtonClickEvent({
      button_name: key === translate('activeKey') ? ButtonTypes.ACTIVE_PROMOS : ButtonTypes.INACTIVE_PROMOS,
    });
  };

  return (
    <PageLayout
      pageLabel={pageLabel}
      isBackButtonEnabled
      className="saved-promos"
    >
      <div className="saved-promo-header">
        <div className="saved-promo-text">My Saved Suki Club Promos</div>
        <img className="saved-promo-image" src={SavedPromos} alt="promo" />
      </div>
      <Tabs type="card" onTabClick={onTabSwitch}>
        <TabPane
          tab={(
            <div className="tab-pane">
              {translate('activeTitle')}
            </div>
          )}
          key={translate('activeKey')}
        >
          {loading && (
            <div className="spin-container">
              <Spin className="m-auto" />
            </div>
          )}
          {!loading && (
            <VouchersTabContent
              isActive
              vouchers={activeVouchers}
              goToAllPromos={goToAllPromos}
              onVoucherClick={getVoucherDetails}
              imageSrc={NoActivePromos}
              imageAlt={translate('noActivePromos')}
            />
          )}
        </TabPane>
        <TabPane
          tab={<div className="tab-pane">{translate('inactiveTitle')}</div>}
          key={translate('inactiveKey')}
        >
          {loading && (
            <div className="spin-container">
              <Spin className="m-auto" />
            </div>
          )}
          {!loading && (
            <VouchersTabContent
              isActive={false}
              vouchers={inactiveVouchers}
              goToAllPromos={goToAllPromos}
              imageSrc={NoInActivePromos}
              imageAlt={translate('noInactivePromos')}
            />
          )}
        </TabPane>
      </Tabs>
    </PageLayout>
  );
};

export default requireConditions(MySavedPromos);
