import { useQuery } from '@apollo/client';
import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../app-theme';
import { getProfileQuery } from '../../gql/gql_queries';

const UserDataFetch = ({
  children,
}) => {
  const {
    setUserData,
  } = useContext(UserContext);

  const {
    data: profileData,
    loading: loadingProfileData,
  } = useQuery(getProfileQuery);

  useEffect(() => {
    if (profileData) {
      const info = profileData.get_profile;
      setUserData(info);
    }
  }, [profileData, setUserData]);

  return (
    <>
      {!loadingProfileData && children}
    </>
  );
};

export default UserDataFetch;
