import React from 'react';
import { notification } from 'antd';
import { CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

export const sendNotification = (title, description, type, onClick, key) => {
  let icon = <ExclamationCircleOutlined style={{ color: '#aaaaaa' }} />;
  if (type === 'success') {
    icon = <CheckOutlined style={{ color: '#22bb33' }} />;
  } else if (type === 'error' || type === 'failed') {
    icon = <ExclamationCircleOutlined style={{ color: '#bb2124' }} />;
  }
  notification.open({
    message: title,
    description,
    icon,
    onClick,
    key,
  });
};

export const closeNotification = (key) => {
  notification.close(key);
};
