import React, { useCallback, useState } from 'react';

import CheckMark from '../../assets/check_mark.png';
import './provider-card.scss';

const ProviderCard = ({
  data,
  imageProps,
  isSelected = false,
  title,
  subTitle,
  onClick,
}) => {
  const {
    src: imageSrc,
    className: imageClassName,
    fallBackSrc,
  } = imageProps || {};
  const [src, setSrc] = useState(imageSrc || fallBackSrc);
  const onImageLoadError = useCallback(() => {
    setSrc(fallBackSrc);
  }, [fallBackSrc]);

  const onCardClick = useCallback((event) => {
    onClick && onClick(event, data);
  }, [data, onClick]);

  return (
    <div
      className={`provider-card ${isSelected && 'selected'}`}
      onClick={onCardClick}
    >
      {imageProps && (
        <img
          src={src}
          alt={imageProps.alt}
          onError={onImageLoadError}
          className={`card-icon ${imageClassName}`}
        />
      )}
      <div className="details-container">
        {title && (
          <div
            className="details-title"
          >
            {title}
          </div>
        )}
        {subTitle && (
          <div
            className="details-sub-title"
          >
            {subTitle}
          </div>
        )}
      </div>
      {isSelected && (
        <img
          className="card-check-icon"
          src={CheckMark}
          alt="Checked"
        />
      )}
    </div>
  );
};

export default ProviderCard;
