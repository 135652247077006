import React, { useMemo } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  stepConnectorClasses,
} from '@mui/material';
import {
  Button,
} from 'antd';
import { styled } from '@mui/material/styles';

import './onboarding-step.scss';
import SukiPromoClubImageBackGround from '../suki-promo-club-image/suki-promo-club-image';
import PageLayout from '../page-layout/page-layout';

const QontoConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#35F6E9',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#35F6E9',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#ffffff',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')(({ ownerState }) => ({
  color: '#ffffff',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...((ownerState.active || ownerState.completed) && {
    color: '#35F6E9',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#35F6E9',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active, completed }} className={className}>
      <div className="QontoStepIcon-circle" />
    </QontoStepIconRoot>
  );
}

const OnBoardingStep = ({
  className = '',
  pageLabel,
  totalSteps = 4,
  currentStep = 1,
  title,
  subTitle,
  buttonProps = {
    className: '',
    isValid: false,
    isLoading: false,
    name: 'Continue',
  },
  children,
}) => {
  const steps = useMemo(() => [...Array(totalSteps).keys()], [totalSteps]);
  return (
    <PageLayout
      pageLabel={pageLabel}
      isBackButtonEnabled={false}
      className={`onboarding-step ${className}`}
    >
      <div className="onboarding-container">
        <SukiPromoClubImageBackGround stickyLogo={false} />
        <div className="section-container">
          <Stepper
            activeStep={currentStep - 1}
            alternativeLabel
            connector={<QontoConnector />}
          >
            {steps.map((label) => (
              <Step key={`step-${label}`}>
                <StepLabel StepIconComponent={QontoStepIcon} />
              </Step>
            ))}
          </Stepper>
          <div className="section-title">
            {title}
          </div>
          <div className="section-sub-title">
            {subTitle}
          </div>
        </div>
        <div className="content">
          {children}
        </div>
      </div>
      <div className="footer">
        <Button
          className={`${buttonProps.className} action button`}
          loading={buttonProps.isLoading}
          disabled={!buttonProps.isValid}
          onClick={buttonProps.onClick}
        >
          {buttonProps.name}
        </Button>
      </div>
    </PageLayout>
  );
};

export default OnBoardingStep;
