import { useQuery } from '@apollo/client';
import React, { useState, useEffect, useCallback } from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { getBarangayList, getCityList, getProvinceList } from '../../utils/getLocalityList';
import { listLocalities } from '../../gql/gql_queries';
import './location-selection.scss';

const LocationSelection = ({ form, onValuesChange }) => {
  const [translate] = useTranslation('registerLocation');

  const [provinceOptions, setProvinceOptions] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState();

  const [barangayOptions, setBarangayOptions] = useState([]);
  const [selectedBarangay, setSelectedBarangay] = useState();

  const [cityOptions, setCityOptions] = useState([]);
  const [selectedCity, setSelectedCity] = useState();

  const {
    data: localityData,
    loading: loadingLocalityList,
    error: localityError,
  } = useQuery(listLocalities);

  const setLocalities = useCallback(() => {
    if (localityError) {
      setProvinceOptions(getProvinceList({}));
    }
    if (!loadingLocalityList && localityData && localityData.locality_data) {
      const localities = localityData.locality_data;
      setProvinceOptions(getProvinceList(localities));
    }
  }, [loadingLocalityList, localityData, localityError]);

  useEffect(() => { setLocalities(); }, [setLocalities]);

  const onCityChange = useCallback((value) => {
    if (selectedCity && selectedCity !== value) {
      setSelectedBarangay();
      form.setFieldsValue({ barangay: '' });
      onValuesChange({ barangay: '' }, form.getFieldsValue());
    }
    setSelectedCity(value);
    const barangays = getBarangayList(value);
    setBarangayOptions(barangays);
  }, [form, onValuesChange, selectedCity]);

  const onProvinceChange = useCallback((value) => {
    if (selectedProvince && selectedProvince !== value) {
      onCityChange();
      form.setFieldsValue({ city: '' });
      onValuesChange({ city: '' }, form.getFieldsValue());
    }
    setSelectedProvince(value);
    const cities = getCityList(value);
    setCityOptions(cities);
  }, [form, onCityChange, onValuesChange, selectedProvince]);

  // eslint-disable-next-line max-len
  const filterOption = useCallback((input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0, []);

  return (
    <>
      <div className="form-item-container">
        <p className="label">
          {translate('fields.province')}
          <span className="required-asterisk"> *</span>
        </p>
        <Form.Item
          name="province"
          rules={[{ required: true, message: 'Please select your province' }]}
        >
          <Select
            className="location-select"
            dropdownClassName=""
            dropdownStyle=""
            options={provinceOptions}
            showSearch
            placeholder={translate('fields.select-province')}
            onChange={onProvinceChange}
            filterOption={filterOption}
            loading={loadingLocalityList}
          />
        </Form.Item>
      </div>
      <div className="form-item-container">
        <p className="label">
          {translate('fields.city')}
          <span className="required-asterisk"> *</span>
        </p>
        <Form.Item
          name="city"
          rules={[{ required: true, message: 'Please select your city' }]}
          valuePropName
        >
          <Select
            className="location-select"
            dropdownClassName=""
            dropdownStyle=""
            value={selectedCity}
            options={cityOptions}
            showSearch
            placeholder={translate('fields.select-city')}
            onChange={onCityChange}
            filterOption={filterOption}
            loading={loadingLocalityList}
          />
        </Form.Item>
      </div>
      <div className="form-item-container">
        <p className="label">
          {translate('fields.barangay')}
          <span className="required-asterisk"> *</span>
        </p>
        <Form.Item
          name="barangay"
          rules={[{ required: true, message: 'Please select your barangay' }]}
          valuePropName
        >
          <Select
            className="location-select"
            dropdownClassName=""
            dropdownStyle=""
            value={selectedBarangay}
            options={barangayOptions}
            showSearch
            placeholder={translate('fields.select-barangay')}
            onChange={(value) => setSelectedBarangay(value)}
            filterOption={filterOption}
            loading={loadingLocalityList}
          />
        </Form.Item>
      </div>
    </>
  );
};

export default LocationSelection;
