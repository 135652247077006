import React, {
  useCallback, useContext, useEffect, useState, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  Form, Input, Select,
} from 'antd';
import debounce from 'lodash.debounce';

import {
  OnBoardingStep,
} from '../../components';
import { useAnalytics, UserContext } from '../../app-theme';
import { sendNotification } from '../../utils/sendNotification';
import { AnalyticsEventTypes, ButtonTypes, PageLabels } from '../../app-theme/constants';

import './register-demographic.scss';
import { requireConditions } from '../../hocs';
import { updateProfileQuery } from '../../gql/gql_mutations';

const maritalStatusOptions = [
  {
    label: 'Single',
    value: 'Single',
  },
  {
    label: 'Married',
    value: 'Married',
  },
  {
    label: 'Separated / Divorced',
    value: 'Separated / Divorced',
  },
  {
    label: 'Widowed',
    value: 'Widowed',
  },
];

const childrenOption = [...Array(11).keys()].map((item) => {
  if (item !== 10) {
    return {
      label: `${item}`,
      value: `${item}`,
    };
  }
  return {
    label: '10+',
    value: '10+',
  };
}, []);

const employmentStatusOptions = [
  {
    label: 'Full-time employed',
    value: 'Full-time employed',
  },
  {
    label: 'Part-time employed',
    value: 'Part-time employed',
  },
  {
    label: 'Unemployed',
    value: 'Unemployed',
  },
  {
    label: 'Self-employed',
    value: 'Self-employed',
  },
  {
    label: 'Home-maker',
    value: 'Home-maker',
  },
  {
    label: 'Student',
    value: 'Student',
  },
  {
    label: 'Retired',
    value: 'Retired',
  },
];

const householdIncomeOptions = [
  {
    label: 'Below P11,000',
    value: 'Below P11,000',
  },
  {
    label: 'P11,001 to 22,000',
    value: 'P11,001 to 22,000',
  },
  {
    label: 'P22,001 to 44,000',
    value: 'P22,001 to 44,000',
  },
  {
    label: 'P44,001 to 80,000',
    value: 'P44,001 to 80,000',
  },
  {
    label: 'P80,001 and above',
    value: 'P80,001 and above',
  },
];

const RegisterDemographicInfo = ({
  completedDemographicInfo,
  completedPreferences,
  referrerLocation,
  referrerState,
}) => {
  const {
    setUserData,
  } = useContext(UserContext);
  const {
    fireEvent,
    fireButtonClickEvent,
    pageLabel,
  } = useAnalytics({
    pageLabel: PageLabels.REGISTER_DEMOGRAPHIC_INFO,
  });
  const [translate] = useTranslation('registerDemographic');
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();

  const [updateProfile] = useMutation(updateProfileQuery);

  const onFormSubmit = useCallback((formData) => {
    setLoading(true);
    fireButtonClickEvent({
      button_name: ButtonTypes.CONTINUE,
    });
    setLoading(true);

    updateProfile({
      variables: {
        ...formData,
      },
    })
      .then(({ data }) => {
        if (data && data.update_profile) {
          setUserData(data.update_profile);
          history.replace({
            pathname: '/preferences',
            state: referrerState,
          });
        } else {
          sendNotification(translate('errors.update-failed'), '', 'error');
        }
      })
      .catch(() => {
        sendNotification(translate('errors.update-failed'), '', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [fireButtonClickEvent, history, referrerState, setUserData, translate, updateProfile]);

  const [form] = Form.useForm();

  const onSubmitForm = useCallback((data) => {
    onFormSubmit({
      ...data,
    });
  }, [onFormSubmit]);

  const fireFormEvents = useCallback((value) => {
    const valueKeys = Object.keys(value);
    const textFields = ['email'];
    textFields.forEach((field) => {
      if (valueKeys.includes(field)) {
        fireEvent(AnalyticsEventTypes.ENTER_TEXT, {
          field_name: field,
          value: value[field],
        });
      }
    });
    const dropDownFields = ['marital_status', 'employment_status', 'monthly_income', 'no_of_children'];
    dropDownFields.forEach((field) => {
      if (valueKeys.includes(field)) {
        fireEvent(AnalyticsEventTypes.SELECT_DROPDOWN_OPTION, {
          field_name: field,
          value: value[field],
        });
      }
    });
  }, [fireEvent]);

  const onValuesChange = useCallback((value) => {
    fireFormEvents(value);
  }, [fireFormEvents]);

  const debouncedChangeHandler = useMemo(() => debounce(onValuesChange, 300), [onValuesChange]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);

  const onFooterClick = useCallback(() => {
    form.submit();
  }, [form]);

  return (
    <>
      {(completedDemographicInfo || completedPreferences) && (
        <Redirect to={referrerLocation} />
      )}
      <OnBoardingStep
        className="register-demographic no-spacing"
        currentStep={3}
        totalSteps={4}
        pageLabel={pageLabel}
        title={translate('title')}
        subTitle={translate('sub-title')}
        buttonProps={{
          isValid: true,
          isLoading,
          name: translate('continue'),
          onClick: onFooterClick,
        }}
      >
        <Form
          className="form"
          form={form}
          layout="vertical"
          initialValues={{
            email: '',
            marital_status: '',
            no_of_children: '',
            employment_status: '',
            monthly_income: '',
          }}
          onFinish={onSubmitForm}
          onValuesChange={debouncedChangeHandler}
          requiredMark={false}
          autoComplete="off"
        >
          <div className="form-item-container">
            <p className="label">
              {translate('fields.email')}
            </p>
            <Form.Item
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'Please enter a valid email address',
                },
              ]}
            >
              <Input
                className="input"
              />
            </Form.Item>
          </div>
          <div className="form-item-container">
            <p className="label">
              {translate('fields.marital-status')}
            </p>
            <Form.Item
              name="marital_status"
            >
              <Select
                className="marital-status-select"
                dropdownClassName=""
                dropdownStyle=""
                options={maritalStatusOptions}
                placeholder={translate('fields.select-option')}
              />
            </Form.Item>
          </div>
          <div className="form-item-container">
            <p className="label">
              {translate('fields.number-of-children')}
            </p>
            <Form.Item
              name="no_of_children"
            >
              <Select
                className="no-of-children-select"
                dropdownClassName=""
                dropdownStyle=""
                options={childrenOption}
                placeholder={translate('fields.select-option')}
              />
            </Form.Item>
          </div>
          <div className="form-item-container">
            <p className="label">
              {translate('fields.employment-status')}
            </p>
            <Form.Item
              name="employment_status"
            >
              <Select
                className="employment-status-select"
                dropdownClassName=""
                dropdownStyle=""
                options={employmentStatusOptions}
                placeholder={translate('fields.select-option')}
              />
            </Form.Item>
          </div>
          <div className="form-item-container">
            <p className="label">
              {translate('fields.monthly-income')}
            </p>
            <Form.Item
              name="monthly_income"
            >
              <Select
                className="monthly-income-select"
                dropdownClassName=""
                dropdownStyle=""
                options={householdIncomeOptions}
                placeholder={translate('fields.select-option')}
              />
            </Form.Item>
          </div>
        </Form>
      </OnBoardingStep>
    </>
  );
};

export default requireConditions(RegisterDemographicInfo);
