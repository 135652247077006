import login from './login.json';
import registrationBasic from './registration-basic.json';
import registerLocation from './register-location.json';
import registerDemographic from './register-demographic.json';
import registerPreferences from './register-preferences.json';
import home from './home.json';
import common from './common.json';
import claim from './claim.json';
import vouchers from './vouchers.json';
import verifyMobileNumber from './verify-mobile-number.json';
import telcoProviders from './telcoProviders.json';
import comingSoon from './coming-soon.json';
import redeemSteps from './redeem-steps.json';
import promoDetails from './promo-details.json';
import voucherDetails from './voucher-details.json';
import categories from './categories.json';

const translations = {
  login,
  registrationBasic,
  registerLocation,
  registerDemographic,
  registerPreferences,
  home,
  common,
  claim,
  vouchers,
  verifyMobileNumber,
  telcoProviders,
  comingSoon,
  redeemSteps,
  promoDetails,
  voucherDetails,
  categories,
};

export default translations;
