import React from 'react';
import sukiPromoClub from '../../assets/SukiClubPromos-Horizontal.svg';
import store from '../../assets/store2.png';
import './suki-promo-club-image.scss';

const SukiPromoClubImageBackGround = ({ showStore = false, stickyLogo = false }) => (
  <div className="page-header">
    <img className={`suki-logo ${stickyLogo && 'suki-logo-sticky'}`} src={sukiPromoClub} alt="growsari" />
    {showStore && (
      <img className="store-logo" src={store} alt="store" style={{ marginTop: stickyLogo ? '12px' : '-50px' }} />
    )}
  </div>
);

export default SukiPromoClubImageBackGround;
