import React, {
  useCallback, useContext, useState,
} from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import {
  Form,
} from 'antd';

import {
  LocationSelection,
  OnBoardingStep,
} from '../../components';
import { useAnalytics, UserContext } from '../../app-theme';
import { sendNotification } from '../../utils/sendNotification';
import { AnalyticsEventTypes, ButtonTypes, PageLabels } from '../../app-theme/constants';

import './register-location.scss';
import { updateProfileQuery } from '../../gql/gql_mutations';
import { requireConditions } from '../../hocs';

const RegistrationBasic = ({
  completedLocationRegistration,
  referrerLocation,
  referrerState,
}) => {
  const {
    fireEvent,
    fireButtonClickEvent,
    pageLabel,
  } = useAnalytics({ pageLabel: PageLabels.REGISTER_LOCATION_INFO });
  const [form] = Form.useForm();
  const [formValid, setFormValid] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [translate] = useTranslation('registerLocation');
  const { setUserData } = useContext(UserContext);
  const history = useHistory();

  const [updateProfile] = useMutation(updateProfileQuery);

  const onSubmitForm = useCallback((formData) => {
    fireButtonClickEvent({
      button_name: ButtonTypes.CONTINUE,
    });
    setLoading(true);

    updateProfile({
      variables: {
        ...formData,
      },
    })
      .then(({ data }) => {
        if (data && data.update_profile) {
          setUserData(data.update_profile);
          history.push({
            pathname: '/demographic-info',
            state: referrerState,
          });
        } else {
          sendNotification(translate('errors.update-failed'), '', 'error');
        }
      })
      .catch((error) => {
        console.log('location-update-error', error);
        sendNotification(translate('errors.update-failed'), '', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [fireButtonClickEvent, updateProfile, setUserData, history, referrerState, translate]);

  const fireFormEvents = useCallback((value) => {
    const valueKeys = Object.keys(value);
    const dropDownFields = ['province', 'barangay', 'city'];
    dropDownFields.forEach((field) => {
      if (valueKeys.includes(field)) {
        fireEvent(AnalyticsEventTypes.SELECT_DROPDOWN_OPTION, {
          field_name: field,
          value: value[field],
        });
      }
    });
  }, [fireEvent]);

  const onValuesChange = useCallback((value, allValues) => {
    fireFormEvents(value);
    const requiredKeys = [
      'province',
      'barangay',
      'city',
    ];
    const isValid = requiredKeys.reduce((acc, key) => {
      if (acc) {
        if (allValues[key]) {
          return true;
        }
        return false;
      }
      return acc;
    }, true);
    setFormValid(isValid);
  }, [fireFormEvents]);

  const onFooterClick = useCallback(() => {
    form.submit();
  }, [form]);

  return (
    <>
      {completedLocationRegistration && (
        <Redirect to={referrerLocation} />
      )}
      <OnBoardingStep
        className="register-location-page no-spacing"
        currentStep={2}
        totalSteps={4}
        pageLabel={pageLabel}
        title={translate('title')}
        subTitle={translate('sub-title')}
        buttonProps={{
          isValid: formValid,
          isLoading,
          name: translate('continue'),
          onClick: onFooterClick,
        }}
      >
        <Form
          className="form"
          form={form}
          layout="vertical"
          onFinish={onSubmitForm}
          onValuesChange={onValuesChange}
          requiredMark={false}
          autoComplete="off"
        >
          <LocationSelection
            form={form}
            onValuesChange={onValuesChange}
          />
        </Form>
      </OnBoardingStep>
    </>
  );
};

export default requireConditions(RegistrationBasic);
