import React, { useContext, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { UserContext } from '../../app-theme';

const requireConditions = (WrappedComponent) => (props) => {
  const {
    location,
  } = props;
  const { userData, isLoggedIn } = useContext(UserContext);

  const completedBasicRegistration = useMemo(() => userData.registered || false, [userData]);
  const completedLocationRegistration = useMemo(() => {
    const isProvinceDataAvailable = !!userData.province;
    const isBarangayDataAvailable = !!userData.barangay;
    const isCityDataAvailable = !!userData.city;
    return isProvinceDataAvailable && isBarangayDataAvailable && isCityDataAvailable;
  }, [userData]);

  const completedPreferences = useMemo(
    () => !!userData.preferred_categories && userData.preferred_categories.length > 0,
    [userData],
  );

  const completedDemographicInfo = useMemo(() => {
    const isEmailAvailable = userData.email !== null;
    const isMaritalStatusAvailable = userData.marital_status !== null;
    const isChildrenDataAvailable = userData.no_of_children !== null;
    const isEmploymentStatusAvailable = userData.employment_status !== null;
    const isMonthlyIncomeAvailable = userData.monthly_income !== null;
    return isEmailAvailable
      || isMaritalStatusAvailable
      || isChildrenDataAvailable
      || isEmploymentStatusAvailable
      || isMonthlyIncomeAvailable;
  }, [userData]);

  const promosEnabled = useMemo(() => userData.promos_enabled || false, [userData]);

  const referrerState = useMemo(() => {
    const { referrer, ...others } = location.state || {};
    return {
      referrer: referrer || location,
      ...others,
    };
  }, [location]);

  const completedAllPostLoginConditions = useMemo(
    () => completedBasicRegistration && completedLocationRegistration && completedPreferences && promosEnabled,
    [completedBasicRegistration, completedLocationRegistration, completedPreferences, promosEnabled],
  );

  const canAccessComponent = useMemo(() => {
    if (isLoggedIn) {
      return completedAllPostLoginConditions;
    }
    return true;
  }, [completedAllPostLoginConditions, isLoggedIn]);

  if (canAccessComponent) {
    return (
      <WrappedComponent
        completedPreferences={completedPreferences}
        completedDemographicInfo={completedDemographicInfo}
        completedBasicRegistration={completedBasicRegistration}
        completedLocationRegistration={completedLocationRegistration}
        promosEnabled={promosEnabled}
        {...props}
      />
    );
  }

  if (!completedBasicRegistration) {
    if (location.pathname === '/register') {
      return (
        <WrappedComponent
          completedPreferences={completedPreferences}
          completedDemographicInfo={completedDemographicInfo}
          completedBasicRegistration={completedBasicRegistration}
          completedLocationRegistration={completedLocationRegistration}
          promosEnabled={promosEnabled}
          {...props}
        />
      );
    }
    return (
      <Redirect
        to={{
          pathname: '/register',
          state: referrerState,
        }}
      />
    );
  }

  if (!completedLocationRegistration) {
    if (location.pathname === '/register-location') {
      return (
        <WrappedComponent
          completedPreferences={completedPreferences}
          completedDemographicInfo={completedDemographicInfo}
          completedBasicRegistration={completedBasicRegistration}
          completedLocationRegistration={completedLocationRegistration}
          promosEnabled={promosEnabled}
          {...props}
        />
      );
    }
    return (
      <Redirect
        to={{
          pathname: '/register-location',
          state: referrerState,
        }}
      />
    );
  }

  if (!completedPreferences) {
    if (location.pathname === '/preferences') {
      return (
        <WrappedComponent
          completedPreferences={completedPreferences}
          completedDemographicInfo={completedDemographicInfo}
          completedBasicRegistration={completedBasicRegistration}
          completedLocationRegistration={completedLocationRegistration}
          promosEnabled={promosEnabled}
          {...props}
        />
      );
    }

    if (!completedDemographicInfo) {
      if (location.pathname === '/demographic-info') {
        return (
          <WrappedComponent
            completedPreferences={completedPreferences}
            completedDemographicInfo={completedDemographicInfo}
            completedBasicRegistration={completedBasicRegistration}
            completedLocationRegistration={completedLocationRegistration}
            promosEnabled={promosEnabled}
            {...props}
          />
        );
      }
      return (
        <Redirect
          to={{
            pathname: '/demographic-info',
            state: referrerState,
          }}
        />
      );
    }

    return (
      <Redirect
        to={{
          pathname: '/preferences',
          state: referrerState,
        }}
      />
    );
  }

  if (!promosEnabled) {
    if (location.pathname === '/coming-soon') {
      return (
        <WrappedComponent
          completedPreferences={completedPreferences}
          completedDemographicInfo={completedDemographicInfo}
          completedBasicRegistration={completedBasicRegistration}
          completedLocationRegistration={completedLocationRegistration}
          promosEnabled={promosEnabled}
          {...props}
        />
      );
    }
    return (
      <Redirect
        to={{
          pathname: '/coming-soon',
          state: referrerState,
        }}
      />
    );
  }

  return null;
};
export default requireConditions;
