import React, { useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { parse } from 'query-string';

import { useAnalytics } from '../../app-theme';
import { PageLabels } from '../../app-theme/constants';

import './self-registration.scss';

const SelfRegistration = () => {
  useAnalytics({
    pageLabel: PageLabels.SELF_REGISTRATION_PAGE,
  });

  const searchParams = useMemo(() => parse(window.location.search), []);

  return (
    <Redirect
      to={{
        pathname: '/login',
        state: {
          fromSelfRegistration: true,
          campaign: {
            id: searchParams.utm_id,
            name: searchParams.utm_campaign,
            medium: searchParams.utm_medium,
          },
        },
      }}
    />
  );
};

export default SelfRegistration;
