import React, { useCallback, useState } from 'react';
import { DateTime } from 'luxon';

import RightArrow from '../../assets/right_arrow.svg';
import './promo-card.scss';

const PromoCard = ({
  promo,
  imageProps,
  onClick,
}) => {
  const {
    src: imageSrc,
    className: imageClassName,
    fallBackSrc,
  } = imageProps || {};
  const [src, setSrc] = useState(imageSrc || fallBackSrc);
  const onImageLoadError = useCallback(() => {
    setSrc(fallBackSrc);
  }, [fallBackSrc]);

  const getSubTitle = useCallback(() => {
    const date = DateTime.fromISO(promo.end_at).setZone('Asia/Manila');
    return `Valid till ${date.toLocaleString(DateTime.DATE_MED)}`;
  }, [promo.end_at]);

  const onCardClick = useCallback(() => {
    onClick && onClick(promo);
  }, [promo, onClick]);

  return (
    <div
      className="detail-container"
      onClick={onCardClick}
    >
      {imageProps && (
        <img
          src={src}
          alt={imageProps.alt}
          onError={onImageLoadError}
          className={`promo-image ${imageClassName}`}
        />
      )}
      {promo.suki_title && (
        <div
          className="details-title"
        >
          {promo.suki_title}
        </div>
      )}
      {promo.end_at && (
        <div
          className="details-sub-title"
        >
          {getSubTitle()}
        </div>
      )}
      <div className="view-promo">
        View Promo
        <img src={RightArrow} alt=">" className="arrow-style" />
      </div>
    </div>
  );
};

export default PromoCard;
