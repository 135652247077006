import React, {
  useCallback, useEffect, useState, useRef, useMemo, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';

import Category from './category';
import { UserContext, useWindowDimensions } from '../../app-theme';

import 'react-multi-carousel/lib/styles.css';
import './category-carousel.scss';

const CategoryCarousel = ({ onSelectCategory, onScroll }) => {
  const { isLoggedIn } = useContext(UserContext);
  const [translate] = useTranslation('categories');
  const allCategories = translate('categoryList', { returnObjects: true });
  const filteredCategories = allCategories.filter((category) => category.title !== 'Just For You');

  const categories = isLoggedIn ? allCategories : filteredCategories;

  const [currentSlide, setCurrentSlide] = useState(0);
  const ref = useRef('');
  const [showShadow, setShowShadow] = useState(false);
  const { width } = useWindowDimensions();
  const responsive = useMemo(() => ({
    all_devices: {
      breakpoint: {
        max: 4000,
        min: 0,
      },
      items: width / 75,
    },
  }), [width]);

  const onSelect = useCallback((slide, category) => {
    setCurrentSlide(slide);
    const { svgPath, ...info } = category;
    onSelectCategory(info);
  }, [onSelectCategory]);

  useEffect(() => {
    function dropShadow() {
      const offSetTop = ref.current && ref.current.containerRef && ref.current.containerRef.current.offsetTop;
      const clientHeight = ref.current && ref.current.containerRef && ref.current.containerRef.current.clientHeight;
      const nextOffSetTop = ref.current && ref.current.containerRef && ref.current.containerRef.current.nextSibling.offsetTop;
      // the margin-bottom used for div is 14px
      const margin = 14;

      if (offSetTop + clientHeight + margin > nextOffSetTop) {
        setShowShadow(true);
      } else {
        setShowShadow(false);
      }
    }
    window.addEventListener('scroll', dropShadow);
    dropShadow();
    return () => { window.removeEventListener('scroll', dropShadow); };
  }, []);

  // function addSentinels(container, className) {
  //   return Array.from(container.querySelectorAll('.category-carousel')).map((el) => {
  //     const sentinel = document.createElement('div');
  //     sentinel.classList.add('sticky_sentinel', className);
  //     return el.parentElement.appendChild(sentinel);
  //   });
  // }

  // function fireEvent(stuck, target) {
  //   console.log(stuck, target);
  //   const e = new CustomEvent('sticky-change', { detail: { stuck, target } });
  //   document.dispatchEvent(e);
  // }

  // const observeHeaders = useCallback((container) => {
  //   const observer = new IntersectionObserver((records, observer) => {
  //     records.forEach((record) => {
  //       const targetInfo = record.boundingClientRect;
  //       const stickyTarget = record.target.parentElement.querySelector('.react-multi-carousel-list');
  //       const rootBoundsInfo = record.rootBounds;
  //       console.log(targetInfo, rootBoundsInfo);
  //       // Started sticking.
  //       if (targetInfo.bottom < rootBoundsInfo.top) {
  //         fireEvent(true, stickyTarget);
  //       }

  //       // Stopped sticking.
  //       if (targetInfo.bottom >= rootBoundsInfo.top
  //           && targetInfo.bottom < rootBoundsInfo.bottom) {
  //         fireEvent(false, stickyTarget);
  //       }
  //     });
  //   }, { threshold: [0], root: container });

  //   // Add the top sentinels to each section and attach an observer.
  //   const sentinels = addSentinels(container, 'sticky_sentinel--top');
  //   sentinels.forEach((el) => observer.observe(el));
  // }, []);

  // const observeStickyHeaderChanges = useCallback((container) => {
  //   observeHeaders(container);
  // }, [observeHeaders]);

  // useEffect(() => {
  //   observeStickyHeaderChanges(document.querySelector('.page-content'));
  // }, [observeStickyHeaderChanges]);

  return (
    <Carousel
      ref={ref}
      className={`category-carousel ${showShadow && 'drop-shadow'}`}
      swipeable
      draggable
      arrows={false}
      slidesToSlide={1}
      responsive={responsive}
      minimumTouchDrag={10}
      keyBoardControl={false}
      afterChange={(previous, event) => onScroll(Math.round(event.currentSlide) + 1)}
      // centerMode
    >
      {categories.map((category, i) => (
        <Category
          key={`category-${category.id}`}
          title={category.title}
          svgPath={category.svgPath}
          selected={i === currentSlide}
          onSelect={() => onSelect(i, category)}
        />
      ))}
    </Carousel>
  );
};
export default CategoryCarousel;

// Add this object to categories.json to include just_for_you category
// {
//   "title": "Just For You",
//   "name": "just for you",
// eslint-disable-next-line max-len
//   "svg_path": "M13.3497 20.1299C12.5897 20.8199 11.4197 20.8199 10.6597 20.1199L10.5497 20.0199C5.29967 15.2699 1.86966 12.1599 1.99966 8.27992C2.05966 6.57992 2.92966 4.94992 4.33966 3.98992C6.97967 2.18992 10.2397 3.02992 11.9997 5.08992C13.7597 3.02992 17.0197 2.17992 19.6597 3.98992C21.0697 4.94992 21.9397 6.57992 21.9997 8.27992C22.1397 12.1599 18.6997 15.2699 13.4497 20.0399L13.3497 20.1299Z"
// }
