import React, { useCallback, useState, useMemo } from 'react';
import { DateTime } from 'luxon';

import Coupon from '../../assets/my_voucher_outline.svg';
import RightArrow from '../../assets/right_arrow.svg';
import ExpiringSoon from '../../assets/expiring_soon.svg';
import Expired from '../../assets/expired.svg';
import Redeemed from '../../assets/redeemed.svg';
import './voucher-card.scss';
import { SUKI_COUPON_STATUS } from '../../constants';
import { getSukiCouponEndDate } from '../../utils';

const VoucherCard = ({
  voucher, onClick, isActive = false,
}) => {
  const [src, setSrc] = useState(voucher.coupon.thumbnail_url || Coupon);
  const onImageLoadError = useCallback(() => {
    setSrc(Coupon);
  }, []);

  const subTitle = useMemo(() => {
    const date = voucher.status !== SUKI_COUPON_STATUS.REDEEMED
      ? DateTime.fromISO(getSukiCouponEndDate(voucher)).setZone('Asia/Manila')
      : DateTime.fromISO(voucher.redeemed_at).setZone('Asia/Manila');
    const localDate = date.toLocaleString(DateTime.DATE_MED);
    switch (voucher.status) {
      case SUKI_COUPON_STATUS.INACTIVE:
        return `Expired on ${localDate}`;
      case SUKI_COUPON_STATUS.REDEMPTION_PENDING:
        return 'Redemption Pending';
      case SUKI_COUPON_STATUS.REDEEMED:
        return `Redeemed on ${localDate}`;
      case SUKI_COUPON_STATUS.ACTIVE:
      default:
        return `Valid till ${localDate}`;
    }
  }, [voucher]);

  const status = useMemo(() => {
    switch (voucher.status) {
      case SUKI_COUPON_STATUS.INACTIVE:
        return 'Expired';
      case SUKI_COUPON_STATUS.REDEEMED:
        return 'Redeemed';
      case SUKI_COUPON_STATUS.REDEMPTION_PENDING:
        return 'Redemption Pending';
      case SUKI_COUPON_STATUS.ACTIVE:
      default: {
        const date = DateTime.fromISO(getSukiCouponEndDate(voucher)).setZone('Asia/Manila');
        const currentTime = DateTime.now().setZone('Asia/Manila');
        const daysLeft = (date - currentTime) / (1000 * 60 * 60 * 24);
        if (daysLeft > 3) {
          return 'Valid';
        }
        if (daysLeft <= 3 && daysLeft > 0) {
          return 'Expiring Soon';
        }
        return 'Expired';
      }
    }
  }, [voucher]);

  const className = useMemo(() => {
    switch (status) {
      case 'Expired':
        return 'expired';
      case 'Redeemed':
      case 'Redemption Pending':
        return 'redeemed';
      case 'Expiring Soon':
      default:
        return '';
    }
  }, [status]);

  const statusSrc = useMemo(() => {
    switch (status) {
      case 'Expired':
        return Expired;
      case 'Redeemed':
      case 'Redemption Pending':
        return Redeemed;
      case 'Expiring Soon':
      default:
        return ExpiringSoon;
    }
  }, [status]);

  return (
    <div
      className="voucher-card"
      onClick={() => onClick(voucher)}
      style={{ pointerEvents: isActive ? '' : 'none' }}
    >
      <img
        src={src}
        alt="voucher"
        onError={onImageLoadError}
        className="card-icon"
      />
      <div className="details-container">
        <div
          className="details-title"
        >
          {voucher.coupon.suki_title}
        </div>
        <div
          className="details-sub-title"
        >
          {subTitle}
        </div>
        {status !== 'Valid' && (
          <div className={`voucher-status ${className}`}>
            <img
              className="status-icon"
              src={statusSrc}
              alt="status"
            />
            <div className="status-text">
              {status}
            </div>
          </div>
        )}
        {(status === 'Valid' || status === 'Expiring Soon') && (
          <div className="view-promo">
            Tap to redeem
            <img
              className="arrow-style"
              src={RightArrow}
              alt=">"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default VoucherCard;
