import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Store from '../../assets/redeem_store.svg';
import './stores-list.scss';

const StoresList = React.forwardRef((props, ref) => {
  const {
    stores = [],
    addBottomMargin = false,
  } = props;
  const [translate] = useTranslation('common');
  const noStores = useMemo(() => stores && stores.length === 0, [stores]);

  return (
    <div className={`stores-list ${addBottomMargin ? 'add-bm' : ''}`}>
      <div className="store-list-title" ref={ref}>
        <img className="store-image" src={Store} alt="store" />
        {translate('stores.title')}
      </div>
      {noStores && (
        <p className="no-stores-text">{translate('stores.noStoresText')}</p>
      )}
      {!noStores && stores.map((store, index) => (
        <React.Fragment key={`store-${store.id}`}>
          <div className="store-row">
            <div className="store-title">
              {store.name}
              {store.owner && <div className="store-owner">{`(${store.owner})`}</div>}
            </div>
            <div className="store-address">{store.address}</div>
          </div>
          {index < stores.length - 1 && (<div className="store-divider" />)}
        </React.Fragment>
      ))}
    </div>
  );
});

export default StoresList;
