import React, {
  useCallback, useContext, useEffect, useState, useMemo,
} from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import {
  Form, Input, Button, Checkbox, Modal, Select, DatePicker,
} from 'antd';
import moment from 'moment';
import debounce from 'lodash.debounce';

import {
  OnBoardingStep,
} from '../../components';
import { useAnalytics, UserContext, useWindowDimensions } from '../../app-theme';
import { registerSuki } from '../../gql/gql_mutations';
import { sendNotification } from '../../utils/sendNotification';
import { AnalyticsEventTypes, ButtonTypes, PageLabels } from '../../app-theme/constants';
import Terms from './terms';

import './registration-basic.scss';
import { requireConditions } from '../../hocs';

const countryCode = '+63';

const genderOptions = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

const RegistrationBasic = ({
  completedBasicRegistration,
  referrerLocation,
  referrerState,
}) => {
  const {
    fireEvent,
    fireButtonClickEvent,
    pageLabel,
  } = useAnalytics({ pageLabel: PageLabels.REGISTRATION_BASIC_INFO });
  const [translate] = useTranslation('registrationBasic');
  const [isLoading, setLoading] = useState(false);
  const {
    setUserData,
    userMobileNumber,
    logout,
  } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    if (!userMobileNumber) {
      logout();
    }
  }, [logout, userMobileNumber]);

  const [register] = useMutation(registerSuki);

  const onFormSubmit = useCallback((formData) => {
    setLoading(true);
    register({
      variables: {
        ...formData,
        phone_number: userMobileNumber,
        ...(referrerState.campaign ? {
          reg_campaign: referrerState.campaign.name,
          reg_campaign_id: referrerState.campaign.id,
        } : {}),
      },
    })
      .then((response) => {
        if (response && response.data && response.data.register) {
          setUserData(response.data.register);
          history.push({
            pathname: '/register-location',
            state: referrerState,
          });
        } else {
          sendNotification(translate('errors.registrationFailed'), '', 'error');
        }
      })
      .catch((error) => {
        console.log('registartion error', error);
        sendNotification(translate('errors.registrationFailed'), '', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [history, referrerState, register, setUserData, translate, userMobileNumber]);

  const [form] = Form.useForm();
  const [formValid, setFormValid] = useState(false);
  const [showTermsPopup, setShowTermsPopup] = useState(false);

  const windowDimensions = useWindowDimensions();
  const bodyStyles = useMemo(() => ({
    maxHeight: windowDimensions.height * (70 / 100), // 70 percent of window height
  }), [windowDimensions.height]);

  const toggleTermsPopup = useCallback(() => {
    setShowTermsPopup(!showTermsPopup);
  }, [showTermsPopup]);

  const onSubmitForm = useCallback((data) => {
    fireButtonClickEvent({
      button_name: ButtonTypes.CONTINUE,
    });
    onFormSubmit({
      ...data,
      ...(data.birthday && {
        birthday: moment(data.birthday).format('YYYY-MM-DD'),
      }),
      ...(data.phone_number && {
        phone_number: `${countryCode}${data.phone_number}`,
      }),
    });
  }, [fireButtonClickEvent, onFormSubmit]);

  const fireFormEvents = useCallback((value) => {
    const valueKeys = Object.keys(value);
    if (valueKeys.includes('terms')) {
      fireEvent(AnalyticsEventTypes.CLICK_CHECKBOX, {
        field_name: 'terms',
        checked: value.terms,
      });
    }
    if (valueKeys.includes('birthday')) {
      fireEvent(AnalyticsEventTypes.ENTER_TEXT, {
        field_name: 'birthday',
        value: value.birthday && moment(value.birthday).format('YYYY-MM-DD'),
      });
    }
    const textFields = ['first_name', 'last_name'];
    textFields.forEach((field) => {
      if (valueKeys.includes(field)) {
        fireEvent(AnalyticsEventTypes.ENTER_TEXT, {
          field_name: field,
          value: value[field],
        });
      }
    });
    const dropDownFields = ['gender'];
    dropDownFields.forEach((field) => {
      if (valueKeys.includes(field)) {
        fireEvent(AnalyticsEventTypes.SELECT_DROPDOWN_OPTION, {
          field_name: field,
          value: value[field],
        });
      }
    });
  }, [fireEvent]);

  const onValuesChange = useCallback((value, allValues) => {
    fireFormEvents(value);
    const requiredKeys = [
      'first_name',
      'last_name',
      'gender',
      'birthday',
      'terms',
    ];
    const isValid = requiredKeys.reduce((acc, key) => {
      if (acc) {
        if (allValues[key]) {
          return true;
        }
        return false;
      }
      return acc;
    }, true);
    setFormValid(isValid);
  }, [fireFormEvents]);

  const debouncedChangeHandler = useMemo(() => debounce(onValuesChange, 300), [onValuesChange]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);

  const disableDate = useCallback((current) => current && current > moment().endOf('day'), []);

  const checkName = useCallback((_, value) => {
    const pattern = /^[A-Za-z]/;

    if (value === '' || pattern.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Name cannot start with special character or number'));
  }, []);

  const onFooterClick = useCallback(() => {
    form.submit();
  }, [form]);

  return (
    <>
      {completedBasicRegistration && (
        <Redirect to={referrerLocation} />
      )}
      <OnBoardingStep
        className="registration-page no-spacing"
        currentStep={1}
        totalSteps={4}
        pageLabel={pageLabel}
        title={translate('title')}
        subTitle={translate('sub-title')}
        buttonProps={{
          isValid: formValid,
          isLoading,
          name: translate('continue'),
          onClick: onFooterClick,
        }}
      >
        <Form
          className="form"
          form={form}
          layout="vertical"
          initialValues={{
            terms: false,
          }}
          onFinish={onSubmitForm}
          onValuesChange={debouncedChangeHandler}
          requiredMark={false}
          autoComplete="off"
        >
          <div className="form-item-container">
            <p className="label">
              {translate('fields.first-name')}
              <span className="required-asterisk"> *</span>
            </p>
            <Form.Item
              name="first_name"
              rules={[{ required: true, message: 'Please input your first name' }, { validator: checkName }]}
            >
              <Input className="input" />
            </Form.Item>
          </div>
          <div className="form-item-container">
            <p className="label">
              {translate('fields.last-name')}
              <span className="required-asterisk"> *</span>
            </p>
            <Form.Item
              required
              name="last_name"
              rules={[{ required: true, message: 'Please input your last name' }, { validator: checkName }]}
            >
              <Input className="input" />
            </Form.Item>
          </div>
          <div className="form-item-container">
            <p className="label">
              {translate('fields.gender')}
              <span className="required-asterisk"> *</span>
            </p>
            <Form.Item
              name="gender"
            >
              <Select
                className="gender-select"
                dropdownClassName=""
                dropdownStyle=""
                options={genderOptions}
                placeholder={translate('fields.select-option')}
              />
            </Form.Item>
          </div>
          <div className="form-item-container">
            <p className="label">
              {translate('fields.birthday')}
              <span className="required-asterisk"> *</span>
            </p>
            <Form.Item
              name="birthday"
            >
              <DatePicker
                allowClear={false}
                className="ant-input"
                format="MM/DD/YYYY"
                placeholder="MM/DD/YYYY"
                disabledDate={disableDate}
              />
            </Form.Item>
          </div>
          <Form.Item name="terms" valuePropName="checked">
            <Checkbox>
              <p className="terms">
                {translate('fields.terms')}
                <button
                  className="terms-button"
                  type="button"
                  onClick={toggleTermsPopup}
                >
                  {translate('fields.terms-button')}
                </button>
              </p>
            </Checkbox>
          </Form.Item>
        </Form>
        <Modal
          wrapClassName="terms-modal"
          title={(
            <div className="title">Terms and Conditions</div>
        )}
          centered
          visible={showTermsPopup}
          onCancel={toggleTermsPopup}
          width="90%"
          bodyStyle={bodyStyles}
          footer={(
            <Button
              className="button"
              onClick={toggleTermsPopup}
            >
              Close
            </Button>
        )}
        >
          <div className="body">
            <Terms />
          </div>
        </Modal>
      </OnBoardingStep>
    </>
  );
};

export default requireConditions(RegistrationBasic);
