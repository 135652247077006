import React, { useCallback, useState } from 'react';
import { Modal } from 'antd';

import './voucher-details.scss';

const DripQuestion = ({
  openModal,
  handleDripModalClose,
  question = {},
  handleOptionSubmit,
}) => {
  const [isAnswered, setIsAnswered] = useState(false);
  const handleOnClick = useCallback((value) => () => {
    setIsAnswered(true);
    handleOptionSubmit(value);
  }, [handleOptionSubmit]);

  return (
    <Modal
      className={`${isAnswered && 'answered'}`}
      centered
      visible={openModal}
      maskClosable={false}
      footer={null}
      onCancel={handleDripModalClose}
    >
      <div className="drip-question-modal">
        <img
          className="drip-question-image"
          src={question.header_image}
          alt="brand"
        />
        <div className="drip-question-title">
          {question.title}
        </div>
        <div className="drip-option-group">
          {question.option_group && question.option_group.options.map((option) => (
            <div
              className={`drip-option ${isAnswered && 'answered'}`}
              key={`option-${option.value}`}
              onClick={handleOnClick(option.value)}
            >
              <img
                className="option-image"
                src={option.image_url}
                alt="option"
              />
              <div className="option-value">
                {option.value}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default DripQuestion;
