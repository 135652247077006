import React, {
  useCallback, useMemo, useState, useContext, useEffect,
} from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { Button, Input } from 'antd';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input';

import {
  ContentTemplate,
  LoginCarousel,
  PageLayout,
  SukiPromoClubImageBackGround,
} from '../../components';
import lockImage from '../../assets/lock_frame.png';
import { useAnalytics, UserContext } from '../../app-theme';
import { generateOtpQuery, verifyOtpQuery } from '../../gql/gql_mutations';
import { sendNotification } from '../../utils/sendNotification';
import { AnalyticsEventTypes, ButtonTypes, PageLabels } from '../../app-theme/constants';

import './login.scss';

const Login = ({
  location,
  referrerLocation,
}) => {
  const {
    fireEvent, fireButtonClickEvent, fireViewScreenEvent, pageLabel,
  } = useAnalytics({
    pageLabel: PageLabels.LOGIN_PAGE,
  });
  const [translate] = useTranslation('login');
  const history = useHistory();
  const {
    isLoggedIn,
    updateUserLoginData,
    setUserMobileNumber,
  } = useContext(UserContext);

  const [generateOtp] = useMutation(generateOtpQuery);
  const [verifyOtp] = useMutation(verifyOtpQuery);

  const [isLoading, setLoading] = useState(false);

  // start mobile number collection fields
  const countryCode = '+63';
  const [isOtpRequested, setOtpRequested] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const mobileNumberWithCode = useMemo(() => `${countryCode}${mobileNumber}`, [mobileNumber]);
  const onMobileNumberChange = useCallback((event) => {
    if (event.target.value.length <= 10) {
      setMobileNumber(event.target.value);
    }
  }, []);

  const onPressRestricted = useCallback((event) => {
    ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
  }, []);

  const isMobileNumberValid = useMemo(() => mobileNumber.length === 10, [mobileNumber]);
  const requestOtp = useCallback(() => {
    fireButtonClickEvent({
      button_name: ButtonTypes.GET_OTP,
      requested_user_number: mobileNumberWithCode,
    });
    setLoading(true);
    generateOtp({ variables: { mobile: mobileNumberWithCode } })
      .then(() => {
        setOtpRequested(true);
        fireViewScreenEvent(PageLabels.LOGIN_PAGE_OTP_SECTION);
      })
      .catch(() => {
        setOtpRequested(false);
        sendNotification(translate('errors.otpGenerationFailed'), '', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [fireButtonClickEvent, fireViewScreenEvent, generateOtp, mobileNumberWithCode, translate]);
  // end mobile number collection fields

  // start otp collection fields
  const [otp, setOtp] = useState();

  const onOtpChange = useCallback((value) => {
    setOtp(value);
  }, []);

  const isOtpValid = useMemo(() => otp && otp.length === 6, [otp]);

  const defaultMobileNumber = useMemo(() => {
    if (location && location.state && location.state.phoneNumber) {
      return location.state.phoneNumber;
    }
    return '';
  }, [location]);

  useEffect(() => {
    if (!mobileNumber) {
      onMobileNumberChange && onMobileNumberChange({
        target: {
          value: defaultMobileNumber,
        },
      });
    }
  }, [defaultMobileNumber, mobileNumber, onMobileNumberChange]);

  const onVerifyOtpClick = useCallback(() => {
    fireButtonClickEvent({
      button_name: ButtonTypes.VERIFY_OTP,
      requested_user_number: mobileNumberWithCode,
      screen_name: PageLabels.LOGIN_PAGE_OTP_SECTION,
    });
    if (otp.length === 6) {
      setLoading(true);
      verifyOtp({ variables: { code: otp, mobile: mobileNumberWithCode } })
        .then((response) => {
          updateUserLoginData(response.data.verify_otp);
          // Save mobile number in user provider
          setUserMobileNumber(mobileNumberWithCode);
          history.push(referrerLocation);
        })
        .catch(() => {
          sendNotification(translate('errors.authenticationFailed'), '', 'error');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      sendNotification(translate('errors.enterValidOtp'), '', 'error');
    }
  }, [
    fireButtonClickEvent,
    mobileNumberWithCode,
    otp,
    verifyOtp,
    updateUserLoginData,
    setUserMobileNumber,
    referrerLocation,
    history,
    translate,
  ]);

  const resendOtp = useCallback(() => {
    setLoading(true);
    fireEvent(AnalyticsEventTypes.CLICK_RESEND, {
      screen_name: PageLabels.LOGIN_PAGE_OTP_SECTION,
    });
    generateOtp({ variables: { mobile: mobileNumberWithCode } })
      .then(() => {
        sendNotification(translate('errors.resendOtpSuccess'), '', 'success');
        setOtp(null);
      })
      .catch(() => {
        sendNotification(translate('errors.resendOtpFailed'), '', 'error');
      })
      .finally(() => setLoading(false));
  }, [fireEvent, generateOtp, mobileNumberWithCode, translate]);
  // end otp collection fields

  const onCarouselScroll = useCallback((currentSlide) => {
    fireEvent(AnalyticsEventTypes.SCROLL_CAROUSEL, {
      screen_name: PageLabels.LOGIN_PAGE,
      name_of_carousel: PageLabels.LOGIN_CAROUSEL,
      scroll_depth_of_carousel: currentSlide,
    });
  }, [fireEvent]);

  return (
    <PageLayout
      className="login-page no-spacing"
      pageLabel={pageLabel}
      showLogo={isOtpRequested}
    >
      {isLoggedIn && (
        <Redirect
          to={referrerLocation}
        />
      )}
      {isOtpRequested && (
        <>
          <SukiPromoClubImageBackGround />
          <ContentTemplate pageLabel={pageLabel}>
            <img className="image" src={lockImage} alt="Lock" />
            <p className="title">{translate('title')}</p>
            <p className="sub-title">
              {translate('enterOtp', { phoneNumber: `${countryCode}${mobileNumber}` })}
            </p>
            <OtpInput
              value={otp}
              onChange={onOtpChange}
              numInputs={6}
              separator={false}
              shouldAutoFocus
              containerStyle="otp-container"
              inputStyle="otp-input"
              focusStyle="otp-focus"
              disabledStyle="otp-disabled"
              errorStyle="otp-error"
              isInputNum
            />
            <p className="no-otp-receive">
              {translate('otpNotReceived')}
              <button
                type="button"
                className="resend-otp"
                onClick={resendOtp}
              >
                {translate('resend')}
              </button>
            </p>
            <Button
              className="button login"
              loading={isLoading}
              disabled={!isOtpValid}
              onClick={onVerifyOtpClick}
            >
              {translate('verify')}
            </Button>
          </ContentTemplate>
        </>
      )}
      {!isOtpRequested && (
        <>
          <LoginCarousel onScroll={onCarouselScroll} carouselItems={translate('login_carousel', { returnObjects: true })} />
          <div className="content enter-number">
            <p className="sub-title enter-number">{translate('requestMobileNumber')}</p>
            <Input
              prefix={countryCode}
              type="number"
              pattern="\d*"
              value={mobileNumber}
              placeholder="917XXXXXXX"
              className="input"
              defaultValue={defaultMobileNumber}
              onChange={onMobileNumberChange}
              onKeyDown={onPressRestricted}
            />
            <Button
              className="button login"
              loading={isLoading}
              disabled={!isMobileNumberValid}
              onClick={requestOtp}
            >
              {translate('requestOtp')}
            </Button>
          </div>
        </>
      )}
    </PageLayout>
  );
};

export default Login;
