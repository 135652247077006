import { useState, useCallback, useMemo } from 'react';

export const storageTypes = {
  LOCAL: 'local',
  SESSION: 'session',
};

export const useStorage = (key, defaultValue, type = storageTypes.LOCAL) => {
  const storage = useMemo(
    () => (type === storageTypes.SESSION ? sessionStorage : localStorage),
    [type],
  );
  const stored = storage.getItem(key);
  const initial = stored || defaultValue;

  const [value, setValue] = useState(initial);

  const updateValue = useCallback((updatedValue) => {
    storage.setItem(key, updatedValue);
    setValue(updatedValue);
  }, [key, storage]);

  const removeValue = useCallback(() => {
    storage.removeItem(key);
    setValue(null);
  }, [key, storage]);

  const memoisedValue = useMemo(() => ([
    value,
    updateValue,
    removeValue,
  ]), [value, updateValue, removeValue]);

  return memoisedValue;
};
