export const removeCountryCode = (input) => {
  const match = input.match(/^(\+63|0|63)([0-9]{10})$/);
  if (!match) {
    return null;
  }
  return `${match[2]}`;
};

export const getSukiCouponEndDate = (sukiCoupon) => sukiCoupon.end_at || sukiCoupon.coupon.end_at;

export const getSukiCouponStartDate = (sukiCoupon) => sukiCoupon.start_at || sukiCoupon.coupon.start_at;
