import React, {
  useCallback,
  useLayoutEffect,
  useEffect,
  useState,
  useRef,
  useMemo,
  useContext,
} from 'react';
import {
  Button,
  Spin,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  PromoCardView, PageLayout, SukiPromoClubImageBackGround, CategoryCarousel,
} from '../../components';
import { listSukiClaimableCoupons, listSukiRecommendedCoupons } from '../../gql/gql_queries';
import { requireConditions } from '../../hocs';
import { useAnalytics, UserContext } from '../../app-theme';
import { useUserDetails } from '../../custom-hooks';
import { sendNotification } from '../../utils/sendNotification';
import { AnalyticsEventTypes, ButtonTypes, PageLabels } from '../../app-theme/constants';

import Voucher from '../../assets/my_voucher_icon_green.svg';
import User from '../../assets/user_icon.svg';
import QuestionMark from '../../assets/question_mark_green.svg';
import NoPromos from '../../assets/no_promos.svg';

import './home.scss';
import HomepageTutorial from './homepage_tutorial';

const BATCH_COUNT = 10;

const Home = () => {
  const {
    pageLabel,
    fireButtonClickEvent,
    fireEvent,
  } = useAnalytics({ pageLabel: PageLabels.HOME_PAGE });
  const [commonTranslations] = useTranslation('common');
  const [translate] = useTranslation('home');
  const [categoryTranslate] = useTranslation('categories');

  const { isLoggedIn } = useContext(UserContext);

  const history = useHistory();
  const pageLayoutRef = useRef();
  const promoContainerRef = useRef();
  const [dimensions, setDimensions] = useState();
  const { displayName } = useUserDetails();
  const [data, setData] = useState({
    promos: [],
    loadMore: true,
    cursor: '',
  });

  const initialCategory = () => {
    if (isLoggedIn) {
      return ({
        id: 2,
        title: 'Just For You',
        name: 'just for you',
      });
    }
    return ({
      id: 1,
      title: 'All Promos',
      name: 'all promos',
    });
  };

  const [category, setCategory] = useState(() => initialCategory());
  const areRecommendedVouchers = useMemo(() => isLoggedIn && (category.title === 'Just For You' || false), [isLoggedIn, category]);
  const [isApiErrored, setIsApiErrored] = useState(false);

  const onPromosLoaded = useCallback((completedData) => {
    const newPromos = (areRecommendedVouchers ? completedData.list_recommended_coupons.collection : completedData.list_coupons.collection) || [];
    const updatedPromos = [
      ...(data.promos),
      ...newPromos,
    ];
    setData({
      ...data,
      promos: updatedPromos,
      cursor: areRecommendedVouchers ? completedData.list_recommended_coupons.cursor : completedData.list_coupons.cursor,
      loadMore: !!(areRecommendedVouchers ? completedData.list_recommended_coupons.cursor : completedData.list_coupons.cursor),
    });
  }, [data, areRecommendedVouchers]);

  const [loadNextPromos, { loading }] = useLazyQuery(listSukiClaimableCoupons, {
    onCompleted: onPromosLoaded,
    onError: () => {
      setIsApiErrored(true);
      sendNotification(categoryTranslate('errors.fetchFailed'), '', 'error');
    },
  });

  const [loadNextRecommendedPromos, { loading: recommendedLoading }] = useLazyQuery(listSukiRecommendedCoupons, {
    onCompleted: onPromosLoaded,
    onError: () => {
      setIsApiErrored(true);
      sendNotification(categoryTranslate('errors.fetchFailed'), '', 'error');
    },
  });

  const fetchData = useCallback(async (categoryName = category && category.title) => {
    if (isLoggedIn && categoryName === 'Just For You') {
      await loadNextRecommendedPromos({
        variables: {
          first: BATCH_COUNT,
          after: categoryName === category.title ? data.cursor : '',
        },
      });
    } else {
      await loadNextPromos({
        variables: {
          first: BATCH_COUNT,
          after: categoryName === category.title ? data.cursor : '',
          mega_category: categoryName === 'All Promos' ? null : categoryName,
        },
      });
    }
  }, [isLoggedIn, category, data.cursor, loadNextPromos, loadNextRecommendedPromos]);

  const gotoPromoDetails = useCallback((promo) => {
    fireEvent(AnalyticsEventTypes.CLICK_VOUCHER, {
      voucher_id: promo.id,
      category_name: category.title,
      registered: isLoggedIn || false,
    });
    history.push(`/promo/${promo.code}`);
  }, [fireEvent, category.title, history, isLoggedIn]);

  useLayoutEffect(() => {
    if (pageLayoutRef.current) {
      setDimensions({
        width: pageLayoutRef.current.offsetWidth,
        height: pageLayoutRef.current.offsetHeight,
      });
    }
  }, []);

  useEffect(() => {
    if (!isApiErrored && data.loadMore && !!dimensions && !!promoContainerRef.current) {
      if (dimensions.height > promoContainerRef.current.offsetHeight) {
        fetchData();
      }
    }
  }, [dimensions, fetchData, isApiErrored, data.loadMore, data.promos.length]);

  const mySavedPromos = useCallback(() => {
    fireButtonClickEvent({
      button_name: ButtonTypes.MY_PROMOS,
    });
    history.push('/vouchers');
  }, [fireButtonClickEvent, history]);

  const login = useCallback(() => {
    fireButtonClickEvent({
      button_name: ButtonTypes.LOGIN_OR_REGISTER,
    });
    history.push('/login');
  }, [fireButtonClickEvent, history]);

  const howItWorks = useCallback(() => {
    fireButtonClickEvent({
      button_name: ButtonTypes.HOW_IT_WORKS,
      registered: isLoggedIn || false,
    });
    history.push('/faqs');
  }, [fireButtonClickEvent, history, isLoggedIn]);

  const onSelectCategory = useCallback((categoryInfo) => {
    fireEvent(AnalyticsEventTypes.SELECT_MEGA_CATEGORY, {
      mega_category_id: categoryInfo.id,
      mega_category_name: categoryInfo.title,
      registered: isLoggedIn || false,
    });
    setData({
      promos: [],
      loadMore: false,
      cursor: '',
    });
    setCategory(categoryInfo);
    fetchData(categoryInfo.title);
  }, [fetchData, fireEvent, isLoggedIn]);

  const onCarouselScroll = useCallback((currentSlide) => {
    fireEvent(AnalyticsEventTypes.SCROLL_CAROUSEL, {
      name_of_carousel: PageLabels.MEGA_CATEGORY_CAROUSEL,
      scroll_depth_of_carousel: currentSlide,
    });
  }, [fireEvent]);

  return (
    <PageLayout
      pageLabel={pageLabel}
      className="home-layout"
      ref={pageLayoutRef}
    >
      <SukiPromoClubImageBackGround showStore />
      <div className="nav-section">
        <div className="layout">
          <p className="welcome-field">
            {commonTranslations('welcomeMessage', { username: displayName || 'Suki' })}
          </p>
          <div className="button-layout">
            {isLoggedIn ? (
              <Button block className="button-view" onClick={mySavedPromos}>
                <img src={Voucher} alt="icon" className="icon-style" />
                <div className="button-text">{translate('mySavedPromos')}</div>
              </Button>
            ) : (
              <Button block className="button-view" onClick={login}>
                <img src={User} alt="icon" className="icon-style" />
                <div className="button-text">{translate('loginOrRegister')}</div>
              </Button>
            )}
            <div className="seperator" />
            <Button block className="button-view" onClick={howItWorks}>
              <img src={QuestionMark} alt="?" className="icon-style" />
              <div className="button-text">{translate('howToRedeem')}</div>
            </Button>
          </div>
        </div>
      </div>
      <CategoryCarousel onSelectCategory={onSelectCategory} onScroll={onCarouselScroll} />
      <InfiniteScroll
        dataLength={data.promos.length}
        next={fetchData}
        hasMore={data.loadMore}
        pullDownToRefresh={false}
      >
        {data.promos.length === 0 && !(loading || recommendedLoading) && !data.loadMore && (
          <div className="no-promos">
            <img
              src={NoPromos}
              alt="no-promos"
            />
            <div className="no-promos-text">
              {areRecommendedVouchers
                ? categoryTranslate('noRecommendedPromos')
                : categoryTranslate('noPromos', { category: category && category.name })}
            </div>
          </div>
        )}
        <div
          className="promos-container"
          ref={promoContainerRef}
        >
          {data.promos.map((promo) => (
            <PromoCardView
              key={`promo-${promo.id}`}
              imageProps={{
                src: promo.thumbnail_url,
                alt: 'promo',
              }}
              promo={promo}
              onClick={gotoPromoDetails}
            />
          ))}
        </div>
        {(loading || recommendedLoading) && (
          <div className="spin-wrapper">
            <Spin />
          </div>
        )}
      </InfiniteScroll>
      <HomepageTutorial />
    </PageLayout>
  );
};

export default requireConditions(Home);
