import { useContext, useMemo } from 'react';
import { UserContext } from '../app-theme';

export function useUserDetails() {
  const { userData } = useContext(UserContext);
  const displayName = useMemo(() => userData.first_name || ''.trim(), [userData]);

  return {
    displayName,
  };
}
