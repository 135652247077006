import React from 'react';

import './category-carousel.scss';

const Category = ({
  selected = false, title = '', svgPath = '', onSelect,
}) => (
  <div className="category" onClick={onSelect}>
    <div className={`category-image ${selected && 'selected'}`}>
      <svg
        className={`category-svg ${selected && 'selected'}`}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d={svgPath} />
      </svg>
    </div>
    <div className={`category-title ${selected && 'selected'}`}>
      {title}
    </div>
    {selected && (
      <div className="status-indicator" />
    )}
  </div>
);
export default Category;
