import { gql } from '@apollo/client';

export const getProfileQuery = gql`
  query GetProfile{
    get_profile{
      id
      phone_number
      first_name
      last_name
      gender
      birthday
      registered
      registration_type
      province
      city
      barangay
      email
      marital_status
      no_of_children
      employment_status
      monthly_income
      enrolled_stores {
          id
          name
          address
      }
      telco_provider_id
      promos_enabled
      telco_load_eligible
      preferred_categories
    }
  }
`;

export const listSukiCoupons = gql`
  query ListSukiCoupons($first: Int, $after: String, $status: [SukiCouponStatus]){
    list_suki_coupons(first: $first, after: $after, status: $status){
      collection {
        id
        store_id
        suki_id
        start_at
        end_at
        redeemed_at
        status
        coupon {
          id,
          code,
          name,
          suki_title,
          suki_subtitle,
          start_at,
          end_at,
          discount,
          thumbnail_url,
          status,
        }
      }
      cursor
    }
  }
`;

export const listSukiClaimableCoupons = gql`
  query ListCoupons($first: Int, $after: String, $mega_category: String){
    list_coupons(first: $first, after: $after, mega_category: $mega_category){
      collection {
        id,
        code,
        name,
        suki_title,
        suki_subtitle,
        start_at,
        end_at,
        discount,
        thumbnail_url,
        status,
        category_id,
      }
      cursor
    }
  }`;

export const listSukiRecommendedCoupons = gql`
  query ListRecommendedCoupons($first: Int, $after: String){
    list_recommended_coupons(first: $first, after: $after) {
      collection {
        id,
        code,
        name,
        suki_title,
        suki_subtitle,
        start_at,
        end_at,
        discount,
        thumbnail_url,
        status,
        category_id,
      }
      cursor
    }
  }
`;

export const getCoupon = gql`
  query GetCoupon($id: ID, $code: String){
    get_coupon(id:$id, code:$code){
      id,
      code,
      name,
      suki_title,
      suki_subtitle,
      start_at,
      end_at,
      discount,
      thumbnail_url,
      status,
      suki_coupon {
        id
      }
    }
  }`;

export const getVoucher = gql`
  query GetSukiCoupon($id: ID!){
    get_suki_coupon(id:$id){
      id
      store_id
      suki_id
      start_at
      end_at
      redeemed_at
      status
      coupon {
        id,
        code,
        name,
        suki_title,
        suki_subtitle,
        start_at,
        end_at,
        discount,
        thumbnail_url,
        status,
      }
    }
  }
`;

export const listLocalities = gql`
  query{
    locality_data{
      barangay_list{
      id
      locality
      city
      province
      region
      country
      is_deleted
      code
      updated_at
      is_serviceable
    }
      city_list{
      city
      province
      region
      country
      is_serviceable
    }
    }
  }
`;

export const listTelcoProviders = gql`
  query{
    list_telco_providers{
      collection {
        id,
        provider_id,
        name,
        key,
        category,
        category_id,
        image_url,
        api_service,
        enabled
      }
    }
  }
`;

export const listCategory = gql`
  query ListCategory($first: Int, $after: String, $mega_category: String){
    list_category(first: $first, after: $after, mega_category: $mega_category){
      collection {
        id,
        icon,
        name,
        status,
        mega_category,
      }
      cursor
    }
  }
`;

export const getDripQuestion = gql`
  query GetDripQuestion{
    get_question {
      title
      id
      option_group_name
      status
      type
      prompt_order
      response_required
      option_group {
        id
        name
        options {
          value
          image_url
        }
      }
      start_at
      end_at
      header_image
    }
  }
`;
