import React, {
  useCallback, forwardRef,
} from 'react';
import { useHistory } from 'react-router-dom';

import {
  useAnalytics,
} from '../../app-theme';
import backArrow from '../../assets/back_arrow.png';

import './page-layout.scss';
import { AnalyticsEventTypes } from '../../app-theme/constants';

const PageLayout = forwardRef(({
  className,
  pageLabel,
  isBackButtonEnabled = false,
  customBackClick,
  children,
  minimumHeight = false,
}, ref) => {
  const { fireEvent } = useAnalytics({ firePageEventsOnLoad: false, pageLabel });
  const history = useHistory();

  const onBackClick = useCallback(() => {
    fireEvent(AnalyticsEventTypes.GO_BACK);
    if (customBackClick) {
      customBackClick();
    } else if (history.location.pathname === '/vouchers') {
      history.push('/');
    } else if (history.length > 2) {
      history.goBack();
    } else {
      history.push('/');
    }
  }, [customBackClick, fireEvent, history]);

  return (
    <div ref={ref} className={`page-layout ${className || ''}`}>
      <div className={`page-content ${minimumHeight ? 'no-min-height' : ''}`}>
        {isBackButtonEnabled && (
          <div className="back-container">
            <img
              className="back-arrow"
              src={backArrow}
              alt="back"
              onClick={onBackClick}
            />
          </div>
        )}
        {children}
      </div>
    </div>
  );
});

export default PageLayout;
