import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import VoucherCard from '../vouchers/voucher-card';

const VouchersTabContent = ({
  isActive = false,
  vouchers,
  imageSrc,
  imageAlt,
  goToAllPromos,
  onVoucherClick,
}) => {
  const [translate] = useTranslation('vouchers');
  return (
    <>
      {vouchers.length > 0 && vouchers.map((voucher) => (
        <VoucherCard
          key={`voucher-${voucher.id}`}
          voucher={voucher}
          isActive={isActive}
          onClick={onVoucherClick}
        />
      ))}
      {vouchers.length === 0 && (
        <div className="no-promos-div">
          <img
            className="no-promos-image"
            src={imageSrc}
            alt={imageAlt}
          />
          <div
            className="no-promos-text"
          >
            {translate('noPromosText')}
          </div>
          <Button
            className="button all-promos-button"
            onClick={goToAllPromos}
          >
            {translate('goToAllPromos')}
          </Button>
        </div>
      )}
    </>
  );
};

export default VouchersTabContent;
