export const AnalyticsEventTypes = {
  // GENERAL EVENTS
  VIEW_SCREEN: 'view_screen',
  CLICK_BUTTON: 'click_button',
  SELECT_DROPDOWN_OPTION: 'select_dropdown_options',
  SELECT_TELCO: 'select_telco',
  ENTER_TEXT: 'enter_text',
  CLICK_CHECKBOX: 'click_checkbox',
  SCROLL_CAROUSEL: 'scroll_carousel',
  VIEW_POPUP: 'view_popup',
  CLOSE_POPUP: 'close_popup',
  CLICK_POPUP_BUTTON: 'click_popup_button',
  // SPECIFIC EVENTS
  VIEW_DRIP_POPUP: 'view_drip_popup',
  CLOSE_DRIP_POPUP: 'close_drip_popup',
  ANSWER_DRIP_POPUP: 'answer_drip_popup',
  SELECT_MEGA_CATEGORY: 'select_mega_category',
  CLICK_VOUCHER: 'click_voucher',
  CLAIM_PROMO: 'claim_promo',
  SELF_REG_SUCCESS: 'self_reg_success',
  CLICK_RESEND: 'click_resend',
  GO_BACK: 'go_back',
  SELECT_PREFERRED_CATEGORY: 'select_preferred_category',
  DESELECT_PREFERRED_CATEGORY: 'deselect_preferred_category',
};

export const ButtonTypes = {
  GET_OTP: 'get_OTP',
  VERIFY_OTP: 'cont_OTP',
  CONTINUE: 'continue',
  SUBMIT: 'submit',
  DPA_REG: 'DPA_reg',
  HOW_IT_WORKS: 'faqs',
  CLAIM_PROMOS: 'claim_promos',
  CLAIM_PROMO: 'save_voucher',
  MY_PROMOS: 'my_promos',
  VIEW_SAVED_PROMOS: 'view_all_saved_promos',
  GO_TO_ALL_PROMOS: 'go_to_all_promos',
  ACTIVE_PROMOS: 'active_promos',
  INACTIVE_PROMOS: 'inactive_promos',
  VIEW_VOUCHERS: 'view_vouchers',
  GO_HOME: 'back_to_home',
  DOWNLOAD_QR: 'download_QR',
  HEADER_GO_TO_VOUCHERS: 'header_go_to_vouchers',
  CONTINUE_ULOCATION: 'cont_address',
  CONTINUE_TELCO: 'cont_telco',
  FIND_PARTICIPATING_STORES: 'find_participating_stores',
  FIND_OTHER_STORES: 'find_other_stores',
  LOGIN_OR_REGISTER: 'login_or_register',
  NEXT: 'next',
  GET_STARTED: 'get_started',
};

export const PageLabels = {
  LOGIN_PAGE: 'login',
  LOGIN_PAGE_OTP_SECTION: 'login_OTP',
  LOGIN_CAROUSEL: 'Login Carousel',
  MEGA_CATEGORY_CAROUSEL: 'mega_category_carousel',
  HOME_PAGE: 'homepage',
  HOMEPAGE_TUTORIAL: 'homepage_tutorial',
  HOMEPAGE_TUTORIAL_CAROUSEL: 'homepage_tutorial_carousel',
  HOW_IT_WORKS: 'faqs_page',
  PROMO_DETAILS: 'voucher_details',
  PROMO_QR: 'voucher_QR',
  MY_SAVED_PROMOS: 'my_promos',
  SELECT_AND_CLAIM_PAGE: 'select_promos',
  CLAIM_SUCCESS_PAGE: 'suki_QR',
  VOUCHERS_PAGE: 'view_vouchers',
  SELF_REGISTRATION_PAGE: 'SR_form',
  REGISTRATION_BASIC_INFO: 'register_basic_info',
  REGISTER_LOCATION_INFO: 'register_location_info',
  REGISTER_DEMOGRAPHIC_INFO: 'register_demographic_info',
  REGISTER_PREFERRED_CATEGORIES: 'register_preferred_categories',
  COMING_SOON: 'coming_soon',
  TELCO_COMING_SOON: 'telco_comingsoon',
  TELCO_NETWORK: 'telco_network',
};
