import React, {
  useContext, useMemo, useState, useCallback,
} from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Button, Spin } from 'antd';
import { DateTime } from 'luxon';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { getCoupon } from '../../gql/gql_queries';
import { claimCouponQuery } from '../../gql/gql_mutations';
import {
  PageLayout, StoresList, RedeemSteps, VoucherIcon,
} from '../../components';
import { useAnalytics, UserContext } from '../../app-theme';
import { AnalyticsEventTypes, ButtonTypes, PageLabels } from '../../app-theme/constants';
import { sendNotification } from '../../utils/sendNotification';
import SukiPromo from '../../assets/suki_promo.svg';
import Check from '../../assets/check.svg';
import './promo-details.scss';

import requireConditions from '../../hocs/require-conditions/require-conditions';

const PromoDetails = ({ match, location }) => {
  const { pageLabel, fireEvent } = useAnalytics({ pageLabel: PageLabels.PROMO_DETAILS });
  const history = useHistory();
  const [translate] = useTranslation('promoDetails');
  const promoCode = useMemo(() => match && match.params && match.params.code, [match]);
  const [isSaving, setIsSaving] = useState(false);
  const {
    enrolledStores,
    isLoggedIn,
  } = useContext(UserContext);
  const {
    data: promo,
    loading,
  } = useQuery(getCoupon, {
    variables: {
      code: promoCode,
    },
  });
  const promoDetails = useMemo(() => (promo && promo.get_coupon) || {}, [promo]);
  const isSaved = promoDetails.suki_coupon !== null;
  const getValidDate = useCallback((endDate) => {
    const date = DateTime.fromISO(endDate).setZone('Asia/Manila');
    return `Valid until ${date.toLocaleString(DateTime.DATE_MED)}`;
  }, []);
  const [claimPromo] = useMutation(claimCouponQuery);
  const onPromoClaimClick = useCallback(() => {
    fireEvent(AnalyticsEventTypes.CLAIM_PROMO, {
      button_name: ButtonTypes.CLAIM_PROMO,
      promo_id: promoDetails.id,
      is_logged_in: false,
      registered: isLoggedIn || false,
    });
    if (isLoggedIn) {
      setIsSaving(true);
      claimPromo({
        variables: {
          coupon_id: promoDetails.id,
        },
      }).then(({ data }) => {
        history.push({
          pathname: `/voucher/${data.claim_coupon.id}`,
          state: {
            voucher: data.claim_coupon,
            from: '/promo',
          },
        });
      }).catch((err) => {
        console.log('confirmPromoSelection error', err);
        sendNotification(translate('errors.claimError'), '', 'error');
      }).finally(() => {
        setIsSaving(false);
      });
    } else {
      history.push({
        pathname: '/login',
        state: {
          referrer: location,
        },
      });
    }
  }, [fireEvent, promoDetails.id, isLoggedIn, claimPromo, history, translate, location]);

  const onBackButtonClick = useCallback(() => {
    history.push('/');
  }, [history]);

  if (!(promoCode)) {
    return (
      <Redirect to="/" />
    );
  }

  return (
    <PageLayout
      pageLabel={pageLabel}
      isBackButtonEnabled
      customBackClick={onBackButtonClick}
      className="promo-details"
    >
      {loading && <Spin style={{ padding: '10px' }} />}
      {!loading && promoDetails.id && (
        <>
          {promoDetails.thumbnail_url && (
            <img
              className="promo-image"
              src={promoDetails.thumbnail_url}
              alt="brand"
            />
          )}
          <div className="promo-details-view promo-title">
            <div className="heading">
              <VoucherIcon discount={promoDetails.discount} />
              <div className="title-heading">
                {promoDetails.name}
              </div>
            </div>
            {promoDetails.end_at && (
              <div>
                {getValidDate(promoDetails.end_at)}
              </div>
            )}
          </div>
          <div className="promo-details-view promo-sub-title">
            <div className="heading">
              <img
                src={SukiPromo}
                alt="promo"
              />
              <div className="sub-title-heading">
                {translate('sukiPromo')}
              </div>
            </div>
            <div>
              {promoDetails.suki_subtitle}
            </div>
          </div>
          <StoresList
            pageLabel={pageLabel}
            stores={enrolledStores}
            addBottomMargin
          />
          {isLoggedIn && <RedeemSteps />}
          <div className="save-promo">
            <Button
              disabled={isSaved}
              className="button save-promo-button"
              onClick={onPromoClaimClick}
              loading={isSaving}
            >
              {isSaved ? (
                <div>
                  <img src={Check} alt="check icon" />
                  {translate('promoClaimed')}
                </div>
              ) : translate('savePromo')}
            </Button>
          </div>
        </>
      )}
    </PageLayout>
  );
};

export default requireConditions(PromoDetails);
